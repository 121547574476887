import {
  SearchableVendorTaxonomyKey,
  SEARCHABLE_VENDOR_TAXONOMY_KEYS,
  BANDS_DJS_TAXONOMY_KEY,
  BAR_SERVICES_TAXONOMY_KEY,
  CAKES_DESSERTS_TAXONOMY_KEY,
  CATERING_TAXONOMY_KEY,
  FLORISTS_TAXONOMY_KEY,
  HAIR_MAKEUP_TAXONOMY_KEY,
  PHOTOGRAPHERS_TAXONOMY_KEY,
  PLANNERS_TAXONOMY_KEY,
  VENUES_TAXONOMY_KEY,
  VIDEOGRAPHERS_TAXONOMY_KEY,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { getVendorTaxonomyKeyFromVendorType } from '@zola-helpers/client/dist/es/marketplace/vendorUtils';
import { getVendorTaxonomySwitchFunc } from '@zola-helpers/client/dist/es/marketplace/vendorUtils';
import { AccountWeddingLocationView, OptionFacetView } from '@zola/svc-marketplace-ts-types';

import _uniq from 'lodash/uniq';

import { awardGroupsEnumToClientAwardGroupsEnum } from '~/meta/awards';
import {
  PartialVendorSearch,
  VendorSearchRequestType,
} from '~/pages/couples/explore/components/SearchResults/types/types';
import { FacetParentKey } from '~/types/facets';
import { NumberOrMax, VendorSearchRangeType, OptionFacetType } from '~/types/types';
import { formatDateUtc, parseDateUtc } from '~/util/dateUtils';
import { centsToDollars } from '~/util/priceConversion';

import { NotNullMembersBriefLocation } from './types';

export const MAX_RANGE_INDICATOR = 'max';

export const extractMinMaxFromSlug = (slug: string): VendorSearchRangeType => {
  const splitSlug = slug.split('-');
  let min = 0;
  let max: NumberOrMax = 0;

  if (splitSlug[1] === 'minimum') {
    min = parseInt(splitSlug[2], 10);
    max = MAX_RANGE_INDICATOR;
  } else if (splitSlug[1] === 'maximum') {
    max = parseInt(splitSlug[2], 10);
  } else {
    min = parseInt(splitSlug[1], 10);
    max = splitSlug[2] === MAX_RANGE_INDICATOR ? MAX_RANGE_INDICATOR : parseInt(splitSlug[2], 10);
  }

  return {
    min,
    max,
  };
};

export const defaultSearchLocation: NotNullMembersBriefLocation = {
  name: 'New York',
  stateCode: 'NY',
  slug: 'new-york-ny',
  vendorMarket: {
    id: 3169,
  },
};

export const getBriefLocation = (weddingLocation: AccountWeddingLocationView | null) => {
  if (weddingLocation) {
    const { city, stateProvince, citySlug, vendorMarket } = weddingLocation;
    if (city && stateProvince && citySlug && vendorMarket?.id) {
      return {
        name: city,
        stateCode: stateProvince,
        slug: citySlug,
        vendorMarket: {
          id: vendorMarket.id,
        },
      };
    }
    return defaultSearchLocation;
  }
  return defaultSearchLocation;
};

export const formatMixedDatesArray = (dates: number[]): string[] | null => {
  const formattedArr = dates.reduce((arr: string[], date) => {
    if (date && date !== null) {
      arr.push(formatDateUtc(parseDateUtc(new Date(date)), 'YYYY-MM-DD'));
    }
    return arr;
  }, []);

  return formattedArr.length ? _uniq(formattedArr) : null;
};

export const optionFacetViewToOptionFacetType = (facet: OptionFacetView): OptionFacetType => {
  const { slug, key, parentKey, name, description } = facet;
  return {
    key,
    slug,
    name,
    description,
    displayName: name,
    parentKey: (parentKey || undefined) as FacetParentKey,
  };
};

export const storefrontSearchRequestToVendorSearch = (
  request: VendorSearchRequestType,
  locationSlug?: string | null,
  facetList?: OptionFacetView[] | null
): PartialVendorSearch => {
  const {
    awardGroups: requestAwardGroups,
    metroTypes: requestMetroTypes,
    price: requestPrice,
    capacity: requestCapacity,
    targetDates,
    sortBy,
    sortOrder,
    vendorType,
  } = request;

  const awardGroups = requestAwardGroups?.[0]?.length
    ? { awardGroups: awardGroupsEnumToClientAwardGroupsEnum(requestAwardGroups) }
    : {};

  const metroTypes = requestMetroTypes?.length
    ? {
        metroTypes: requestMetroTypes,
      }
    : {};

  const availabilityDates = targetDates?.length
    ? {
        availabilityDates: formatMixedDatesArray(targetDates),
      }
    : {};

  const sorting = sortBy && sortOrder ? { sorting: { sortOrder, sortBy } } : {};

  const selectedTaxonomy =
    vendorType && getVendorTaxonomyKeyFromVendorType(vendorType)
      ? {
          selectedTaxonomy: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            key: getVendorTaxonomyKeyFromVendorType(vendorType)!,
          },
        }
      : {};

  const selectedLocation = locationSlug
    ? {
        selectedLocation: {
          slug: locationSlug,
        },
      }
    : {};

  const price = requestPrice
    ? {
        price: {
          min: centsToDollars(requestPrice.priceCents.min) || 0,
          max: centsToDollars(requestPrice.priceCents.max) || 'max',
        } as VendorSearchRangeType,
      }
    : {};

  const capacity =
    requestCapacity?.min || requestCapacity?.max
      ? {
          capacity: {
            min: requestCapacity.min || 0,
            max: requestCapacity.max || 'max',
          } as VendorSearchRangeType,
        }
      : {};

  // WIP
  const selectedFacets = facetList?.length
    ? {
        selectedFacets: facetList.map(optionFacetViewToOptionFacetType),
      }
    : {};

  return {
    ...awardGroups,
    ...metroTypes,
    ...availabilityDates,
    ...sorting,
    ...selectedTaxonomy,
    ...selectedLocation,
    ...price,
    ...capacity,
    ...selectedFacets,
  };
};

// @ts-expect-error TODO: Add officiants and extras
const DISPLAY_ORDER = getVendorTaxonomySwitchFunc<SearchableVendorTaxonomyKey, number>({
  [VENUES_TAXONOMY_KEY]: 1,
  [PHOTOGRAPHERS_TAXONOMY_KEY]: 2,
  [HAIR_MAKEUP_TAXONOMY_KEY]: 3,
  [BANDS_DJS_TAXONOMY_KEY]: 4,
  [FLORISTS_TAXONOMY_KEY]: 5,
  [CATERING_TAXONOMY_KEY]: 6,
  [PLANNERS_TAXONOMY_KEY]: 7,
  [CAKES_DESSERTS_TAXONOMY_KEY]: 8,
  [VIDEOGRAPHERS_TAXONOMY_KEY]: 9,
  [BAR_SERVICES_TAXONOMY_KEY]: 10,
});

export const ORDERED_SEARCHABLE_VENDOR_TAXONOMY_KEYS = SEARCHABLE_VENDOR_TAXONOMY_KEYS.sort(
  (a, b) => DISPLAY_ORDER(a) - DISPLAY_ORDER(b)
);
