import { useCallback, useEffect, useMemo, useState } from 'react';

import { UserContext } from '@zola-helpers/client/dist/es/@types';
import {
  PrimaryNavIdentifier,
  SecondaryNavIdentifier,
  TertiaryNavIdentifier,
} from '@zola-helpers/client/dist/es/constants/navConstants';
import { isDesktopV2 } from '@zola-helpers/client/dist/es/util/responsive';
import { MessageStatsView } from '@zola/svc-marketplace-ts-types';
import { AppDownloadBannerMobile } from '@zola/zola-ui/src/components/AppDownloadBannerMobile/AppDownloadBannerMobile';
import { ErrorBoundary } from '@zola/zola-ui/src/components/ErrorBoundary';
import { PreauthFooterV3 } from '@zola/zola-ui/src/components/PreAuthV3';
import { useLocationForVendorSRPLink } from '@zola/zola-ui/src/hooks/useLocationForVendorSRPLink/useLocationForVendorSRPLink';
import { desktopV2Only } from '@zola/zola-ui/src/styles/emotion/mixins/responsiveV2';

import cx from 'classnames';

import { useUserContext } from '~/contexts/UserContext';
import { useMarketplaceSessionCookie } from '~/hooks/useMarketplaceSessionCookie';
import { getMessageStats, getUnreadInquiries } from '~/libs/client/api/inquiries';
import { getNav } from '~/libs/client/api/nav';
import { ActionableInquiryView } from '~/types/responseTypes';
import Logger from '~/util/logger';
import { isGuest } from '~/util/userContextUtils';

import Nav from '../../header/Nav';
import UnrespondedMessagesNotification from '../../notifications/UnrespondedMessagesNotification';

import styles from './mainLayout.module.less';

interface MainLayoutProps {
  id?: string;
  children: React.ReactNode;
  className?: string | { [className: string]: boolean };
  activePrimaryLink?: PrimaryNavIdentifier;
  activeSecondaryLink?: SecondaryNavIdentifier;
  activeTertiaryLink?: TertiaryNavIdentifier;
  disableSecondary?: boolean;

  /** If true, give a hint that we are showing a banner on this page. */
  hasBanner?: boolean;

  /** Optionally hides footer on mobile and tablet */
  hideFooterOnMobile?: boolean;

  /** If true, show the unresponded messages notification.  By default: true */
  showUnrespondedMessages?: boolean;

  /** SSR'd nav html */
  ssrNavHtml?: string | null;

  /** Force the secondary nav to be in a shown state on load.  Prevents CLS for SSR'd pages where the secondary nav will show. */
  showSecondaryNavOnLoad?: boolean;

  /** Show the app download banner on mobile web */
  showAppDownloadBanner?: boolean;
  appDownloadBannerCampaignName?: string;
}

const MainLayout = (props: MainLayoutProps): JSX.Element => {
  const {
    id = '',
    children,
    className,
    activePrimaryLink,
    activeSecondaryLink,
    activeTertiaryLink,
    appDownloadBannerCampaignName = '',
    disableSecondary = false,
    hasBanner = false,
    hideFooterOnMobile = false,
    showUnrespondedMessages = true,
    ssrNavHtml,
    showSecondaryNavOnLoad = false,
    showAppDownloadBanner = false,
  } = props;

  const [navHtml, setNavHtml] = useState('');
  const [unreadInquiries, setUnreadInquiries] = useState<ActionableInquiryView[]>([]);
  const [messageStats, setMessageStats] = useState<MessageStatsView>();
  const userContext = useUserContext();
  const userIsGuest = isGuest(userContext);
  const unrespondedMessagesTotal = messageStats?.unrespondedTotal || 0;
  const useSsrNav = Boolean(ssrNavHtml);

  const shouldSkipLP = useMemo(() => {
    return (
      typeof window !== 'undefined' &&
      isDesktopV2() &&
      userContext?.marketplace_account?.has_inquired
    );
  }, [userContext]);

  const weddingLocation = useLocationForVendorSRPLink({
    shouldCheckLocation: shouldSkipLP || false,
  });

  useMarketplaceSessionCookie(userContext);

  useEffect(() => {
    if (!useSsrNav && userContext && !navHtml) {
      getNav()
        .then(setNavHtml)
        .catch(() => null);
    }
  }, [userContext, navHtml, useSsrNav]);

  useEffect(() => {
    if (!userIsGuest) {
      getUnreadInquiries()
        .then(setUnreadInquiries)
        .catch(() => []);

      getMessageStats()
        .then(setMessageStats)
        .catch(() => null);
    }
  }, [userIsGuest]);

  const SpoofHeader = useCallback(() => {
    if (userContext && userContext.is_spoof) {
      const { first_name: firstName, last_name: lastName } = userContext;
      return (
        <div className="spoof-header">
          <h1>
            Logged in as {firstName} {lastName}
          </h1>
        </div>
      );
    }
    return null;
  }, [userContext]);

  return (
    <div className={cx({ [styles.postAuthNav3PageWrapper]: !userIsGuest })}>
      {showAppDownloadBanner && (
        <AppDownloadBannerMobile campaignName={appDownloadBannerCampaignName} />
      )}
      <Nav
        navHtml={useSsrNav ? (ssrNavHtml as string) : navHtml}
        ssr={useSsrNav}
        user={userContext as UserContext}
        activePrimaryLink={activePrimaryLink}
        activeSecondaryLink={activeSecondaryLink}
        activeTertiaryLink={activeTertiaryLink}
        disableSecondary={disableSecondary}
        showInquiryJewel={unreadInquiries.length > 0}
        showSecondaryOnLoad={showSecondaryNavOnLoad}
      />
      <div
        role="main"
        id={id}
        className={cx('marketplace-layout', className, {
          [styles.isPostAuthNav3]: !userIsGuest,
        })}
      >
        <ErrorBoundary Logger={Logger}>
          <SpoofHeader />
          {showUnrespondedMessages && (
            <UnrespondedMessagesNotification
              unrespondedTotal={unrespondedMessagesTotal}
              hasBanner={hasBanner}
            />
          )}
          {children}
        </ErrorBoundary>
      </div>
      <div
        className={cx(styles.footer, { [styles.isPostAuthNav3]: !userIsGuest })}
        css={hideFooterOnMobile ? desktopV2Only : undefined}
      >
        <PreauthFooterV3 isGuest={userIsGuest} weddingLocation={weddingLocation} />
      </div>
    </div>
  );
};

export default MainLayout;
