import {
  BANDS_DJS_TAXONOMY_KEY,
  BAR_SERVICES_TAXONOMY_KEY,
  CAKES_DESSERTS_TAXONOMY_KEY,
  CATERING_TAXONOMY_KEY,
  FLORISTS_TAXONOMY_KEY,
  HAIR_MAKEUP_TAXONOMY_KEY,
  PHOTOGRAPHERS_TAXONOMY_KEY,
  PLANNERS_TAXONOMY_KEY,
  SearchableVendorTaxonomyKey,
  VENUES_TAXONOMY_KEY,
  VIDEOGRAPHERS_TAXONOMY_KEY,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { getVendorTaxonomySwitchFunc } from '@zola-helpers/client/dist/es/marketplace/vendorUtils';
import { CreateIconComponentProps } from '@zola/zola-ui/src/components/SvgIcons/CreateIconComponent';
import getVendorIconV3 from '@zola/zola-ui/src/util/getVendorIconV3';

import _capitalize from 'lodash/capitalize';
import _uniq from 'lodash/uniq';

import {
  VenueServiceLevelChildKey,
  VenueSettingChildKey,
  VENUE_SERVICE_LEVEL_ALL_INCLUSIVE,
  VENUE_SERVICE_LEVEL_LIMITED_SERVICES,
  VENUE_SERVICE_LEVEL_RAW_SPACE,
  ExtrasServicesChildKey,
  ExtrasVendorTypeChildKey,
} from '~/types/facets';
import { MappedAccountStorefront } from '~/types/mappedResponseTypes';

import { isVenueServiceLevelKey, isVenueSettingKey } from './facetUtils';
import { alphabetical } from './sortUtils';

/**
 * Get the start URL for vendor management, based on the status of the current storefront
 */
export const getVendorStartUrl = (currentStorefront?: MappedAccountStorefront | null): string => {
  if (!currentStorefront) return '/inspire/vendors/onboard';

  return currentStorefront.published ? '/inspire/vendors/dashboard' : '/inspire/vendors/listing';
};

/**
 * Determines whether the vendor category uses a guest count
 */
export const isCategoryWithGuestCount = (
  vendorTaxonomyKey?: SearchableVendorTaxonomyKey
): boolean => {
  return vendorTaxonomyKey
    ? [
        VENUES_TAXONOMY_KEY,
        CATERING_TAXONOMY_KEY,
        BAR_SERVICES_TAXONOMY_KEY,
        CAKES_DESSERTS_TAXONOMY_KEY,
        PLANNERS_TAXONOMY_KEY,
      ].includes(vendorTaxonomyKey)
    : false;
};

/**
 * Indicates if the category either doesn't allow the portfolio or if the category has an
 * optional portfolio.
 */
export const isCategoryWithOptionalPortfolioSection = (
  vendorTaxonomyKey?: SearchableVendorTaxonomyKey
) =>
  vendorTaxonomyKey &&
  [VIDEOGRAPHERS_TAXONOMY_KEY, BANDS_DJS_TAXONOMY_KEY].includes(vendorTaxonomyKey);

const formatArray = (array: string[]) => {
  const [first, ...rest] = array;
  return [_capitalize(first), ...rest].join(' & ');
};

const VENUE_SERVICE_LEVEL_TEXT_MAP = (): Record<VenueServiceLevelChildKey, string> => {
  return {
    [VENUE_SERVICE_LEVEL_ALL_INCLUSIVE]: 'all-inclusive',
    [VENUE_SERVICE_LEVEL_LIMITED_SERVICES]: 'select services',
    [VENUE_SERVICE_LEVEL_RAW_SPACE]: 'raw space',
  };
};
const VENUE_SETTING_TEXT_MAP: Record<VenueSettingChildKey, 'indoor' | 'outdoor'> = {
  'venue-setting-indoor-space': 'indoor',
  'venue-setting-outdoor-space': 'outdoor',
  'venue-setting-covered-outdoor-space': 'outdoor',
};

export const getVenueSettingsTexts = (venueSettings: string[]): ('indoor' | 'outdoor')[] => {
  return venueSettings
    ? _uniq(
        venueSettings
          .map((item) => (isVenueSettingKey(item) ? VENUE_SETTING_TEXT_MAP[item] : undefined))
          .filter((item): item is 'indoor' | 'outdoor' => Boolean(item))
          .sort(alphabetical)
      )
    : [];
};
export const formatVenueCapacity = (maxCapacity: number | null | undefined) => {
  let displayCapacityText;
  if (maxCapacity) {
    if (maxCapacity === 1) {
      displayCapacityText = `Up to ${maxCapacity} guest`;
    } else {
      displayCapacityText = `Up to ${maxCapacity} guests`;
    }
  }
  return displayCapacityText;
};

export const formatVenueServiceLevels = (serviceLevels: string[]) => {
  const displayServiceLevels = serviceLevels
    ? serviceLevels
        .map((item) =>
          isVenueServiceLevelKey(item) ? VENUE_SERVICE_LEVEL_TEXT_MAP()[item] : undefined
        )
        .filter((item): item is VenueServiceLevelChildKey => Boolean(item))
    : [];

  return formatArray(displayServiceLevels);
};

export const formatVenueSettings = (venueSettings: string[]) => {
  const displayVenueSettings: string[] = venueSettings
    ? _uniq(
        venueSettings
          .map((item) =>
            isVenueSettingKey(item) ? (VENUE_SETTING_TEXT_MAP[item] as string) : undefined
          )
          .filter((item): item is VenueSettingChildKey => Boolean(item))
      )
    : [];

  return formatArray(displayVenueSettings);
};

export const formatVenueDetails = (
  serviceLevels: string[],
  venueSettings: string[],
  maxCapacity: number | null | undefined
) => {
  const formattedVenueSettings = formatVenueSettings(venueSettings);
  const formattedServiceLevels = formatVenueServiceLevels(serviceLevels);
  const displayCapacityText = formatVenueCapacity(maxCapacity);

  return { formattedVenueSettings, formattedServiceLevels, displayCapacityText };
};

/**
 * Percentage of total wedding budget that users spend on category
 * From Column P here: https://docs.google.com/spreadsheets/d/1NDjw9bH8cjlj7osX32BiAOHfpNDoAdmlGIuZTQAfHys/edit#gid=0
 */
export const percentSpentOnCategory = getVendorTaxonomySwitchFunc<
  SearchableVendorTaxonomyKey,
  number
  // @ts-expect-error TODO: Add officiants and extras
>({
  [VENUES_TAXONOMY_KEY]: 24,
  [PHOTOGRAPHERS_TAXONOMY_KEY]: 7,
  [VIDEOGRAPHERS_TAXONOMY_KEY]: 5,
  [FLORISTS_TAXONOMY_KEY]: 8,
  [CATERING_TAXONOMY_KEY]: 18,
  [BANDS_DJS_TAXONOMY_KEY]: 9,
  [HAIR_MAKEUP_TAXONOMY_KEY]: 2,
  [CAKES_DESSERTS_TAXONOMY_KEY]: 2,
  [PLANNERS_TAXONOMY_KEY]: 6,
  [BAR_SERVICES_TAXONOMY_KEY]: 5,
});

export type ChipOption = {
  icon: React.FC<CreateIconComponentProps>;
  hed: string;
  dek?: string;
  value: SearchableVendorTaxonomyKey;
};

export const vendorCategoryChipOptions: ChipOption[] = [
  {
    icon: getVendorIconV3(VENUES_TAXONOMY_KEY),
    hed: 'Venues',
    value: VENUES_TAXONOMY_KEY,
  },
  {
    icon: getVendorIconV3(PHOTOGRAPHERS_TAXONOMY_KEY),
    hed: 'Photographers',
    value: PHOTOGRAPHERS_TAXONOMY_KEY,
  },
  {
    icon: getVendorIconV3(VIDEOGRAPHERS_TAXONOMY_KEY),
    hed: 'Videographers',
    value: VIDEOGRAPHERS_TAXONOMY_KEY,
  },
  {
    icon: getVendorIconV3(FLORISTS_TAXONOMY_KEY),
    hed: 'Florists',
    value: FLORISTS_TAXONOMY_KEY,
  },
  {
    icon: getVendorIconV3(CATERING_TAXONOMY_KEY),
    hed: 'Caterers',
    value: CATERING_TAXONOMY_KEY,
  },
  {
    icon: getVendorIconV3(CAKES_DESSERTS_TAXONOMY_KEY),
    hed: 'Bakers',
    value: CAKES_DESSERTS_TAXONOMY_KEY,
  },
  {
    icon: getVendorIconV3(BANDS_DJS_TAXONOMY_KEY),
    hed: 'Musicians',
    value: BANDS_DJS_TAXONOMY_KEY,
  },
  {
    icon: getVendorIconV3(HAIR_MAKEUP_TAXONOMY_KEY),
    hed: 'Beauty',
    value: HAIR_MAKEUP_TAXONOMY_KEY,
  },
  {
    icon: getVendorIconV3(PLANNERS_TAXONOMY_KEY),
    hed: 'Planners',
    value: PLANNERS_TAXONOMY_KEY,
  },
];

/**
 * Extras decor & rentals services subcategory keys
 *
 * @see https://docs.google.com/spreadsheets/d/1QiL3OMHer4IYUONqjgWyMU5yVKK3cdeLQf1RCKNVkDQ/edit?gid=465936957#gid=465936957
 */
export const EXTRAS_DECOR_RENTALS_SERVICES: ExtrasServicesChildKey[] = [
  'extras-services-attire',
  'extras-services-custom-dance-floors',
  'extras-services-floral-preservation',
  'extras-services-transportation',
  'extras-services-wedding-scent-experience',
];

/**
 * Extras pre & post wedding services subcategory keys
 *
 * @see https://docs.google.com/spreadsheets/d/1QiL3OMHer4IYUONqjgWyMU5yVKK3cdeLQf1RCKNVkDQ/edit?gid=465936957#gid=465936957
 */
export const EXTRAS_PRE_POST_WEDDING_SERVICES: ExtrasServicesChildKey[] = [
  'extras-services-calligrapher',
  'extras-services-dance-classes',
  'extras-services-fortune-teller-tarot',
  'extras-services-gifts-favors',
  'extras-services-guest-book-alternatives',
  'extras-services-lighting-av',
  'extras-services-pre-marital-counseling',
  'extras-services-styling',
];

/**
 * Extras day of wedding services subcategory keys
 *
 * @see https://docs.google.com/spreadsheets/d/1QiL3OMHer4IYUONqjgWyMU5yVKK3cdeLQf1RCKNVkDQ/edit?gid=465936957#gid=465936957
 */
export const EXTRAS_DAY_OF_WEDDING_SERVICES: ExtrasServicesChildKey[] = [
  'extras-services-attire',
  'extras-services-child-care',
  'extras-services-cigar-station',
  'extras-services-custom-dance-floors',
  'extras-services-custom-signs',
  'extras-services-fireworks',
  'extras-services-floral-preservation',
  'extras-services-guest-book-alternatives',
  'extras-services-live-painters-artists',
  'extras-services-performers',
  'extras-services-pet-coordination',
  'extras-services-photo-booths',
  'extras-services-poetry',
  'extras-services-rentals',
  'extras-services-styling',
  'extras-services-other',
];

export const EXTRAS_VENDOR_TYPE_ROOT_ID = 823;

/**
 * Whether the facet key is a subcategory of decor & rentals
 */
export const isExtrasDecorRentalsServices = (facetKey: string) => {
  return EXTRAS_DECOR_RENTALS_SERVICES.includes(facetKey as ExtrasServicesChildKey);
};

/**
 * Whether the facet key is a subcategory of pre & post wedding services
 */
export const isExtrasPrePostWeddingServices = (facetKey: string) => {
  return EXTRAS_PRE_POST_WEDDING_SERVICES.includes(facetKey as ExtrasServicesChildKey);
};

/**
 * Whether the facet key is a subcategory of day of wedding services
 */
export const isExtrasDayOfWeddingServices = (facetKey: string) => {
  return EXTRAS_DAY_OF_WEDDING_SERVICES.includes(facetKey as ExtrasServicesChildKey);
};

/**
 * Returns the vendor types based on the specified services facet keys
 */
export const getExtrasVendorTypesForServices = (facetKeys: string[]) => {
  const vendorTypes = new Set<ExtrasVendorTypeChildKey>();
  facetKeys.forEach((key) => {
    if (isExtrasDecorRentalsServices(key)) {
      vendorTypes.add('extras-vendor-type-decor-rentals');
    }
    if (isExtrasPrePostWeddingServices(key)) {
      vendorTypes.add('extras-vendor-type-pre-post-wedding');
    }
    if (isExtrasDayOfWeddingServices(key)) {
      vendorTypes.add('extras-vendor-type-day-of-wedding');
    }
  });
  return [...vendorTypes];
};
