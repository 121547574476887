import FeedbackIcon from '@zola/zola-ui/src/assets/images/icons/feeling_nothing.svg';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';

import cx from 'classnames';

import { VENDOR_SUPPORT_EMAIL } from '~/util/vendorSupportEmail';
import './feedbackWidget.less';

interface FeedbackWidgetProps {
  className?: string;
}

const FeedbackWidget = (props: FeedbackWidgetProps) => {
  const { className } = props;

  const classes = cx('marketplace__feedback-widget', className);

  return (
    <div className={classes}>
      <img className="feedback-widget__icon" src={FeedbackIcon} alt="" aria-hidden="true" />
      <span className="feedback-widget__heading">Have Feedback?</span>
      <div className="feedback-widget__more-info">
        <div>Reach out to us at</div>
        <LinkV2
          sizes="small"
          inline
          href={`mailto:${VENDOR_SUPPORT_EMAIL}?subject=Vendor Feedback`}
        >
          {VENDOR_SUPPORT_EMAIL}
        </LinkV2>
      </div>
    </div>
  );
};

export default FeedbackWidget;
