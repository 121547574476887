import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import type { UserContext } from '@zola-helpers/client/dist/es/@types';
import NAV_DATA, {
  FeatureFlagsProductType,
  PrimaryNavIdentifier,
  SecondaryNavItem,
} from '@zola-helpers/client/dist/es/constants/navConstants';
import { publishFeatureFlagOverrides } from '@zola-helpers/client/dist/es/featureFlags';
import {
  getSearchedLocation,
  getSearchLocationForBriefLocation,
} from '@zola-helpers/client/dist/es/marketplace/vendorSearchUtils';
import { isDesktopV2 } from '@zola-helpers/client/dist/es/util/responsive';
import PreauthNavSkeletonLoader from '@zola/zola-ui/src/components/PreauthNavSkeletonLoader';
import useEffectOnce from '@zola/zola-ui/src/hooks/useEffectOnce';
import { MEDIA_QUERY } from '@zola/zola-ui/src/styles/emotion';

import cx from 'classnames';
import Head from 'next/head';

import { useSearchedLocation } from '~/contexts/SearchContext';
import useWeddingLocation from '~/hooks/useWeddingLocation';
import { getBriefLocation } from '~/pages/couples/explore/util/helpers';
import { LocationOrAllMarketsType } from '~/types/responseTypes';
import { FLAGS } from '~/util/featureFlags';
import { isGuest } from '~/util/userContextUtils';

import initNavData from './util/navData';
import { getNavHeights, getNavSource, showSecondaryNav, showTertiaryNav } from './util/navUtils';
import getSecondaryNavItems, { getExploreVendorsDropdownItems } from './util/secondaryNavData';

import styles from './nav.module.less';

const {
  EVENTS: { NAV_LOADED },
} = NAV_DATA;

type NavProps = {
  navHtml: string | null;
  user?: UserContext | null;
  activePrimaryLink?: string;
  activeSecondaryLink?: string;
  activeTertiaryLink?: string;
  disablePrimaryNavCollapse?: boolean;
  disableSecondary?: boolean;
  hideBanner?: boolean;
  showInquiryJewel?: boolean;
  className?: string;
  ssr?: boolean;
  showSecondaryOnLoad?: boolean;
};

const Nav: React.FC<NavProps> = ({
  navHtml,
  user = null,
  activePrimaryLink = PrimaryNavIdentifier.VENDORS,
  activeSecondaryLink,
  activeTertiaryLink,
  disablePrimaryNavCollapse = false,
  disableSecondary = false,
  hideBanner = false,
  showInquiryJewel = false,
  showSecondaryOnLoad = false,
  children,
  ssr = false,
}): JSX.Element => {
  const { navScript, navStyle } = getNavSource(navHtml);
  const userIsGuest = isGuest(user);
  const [showAnnouncement, setShowAnnouncement] = useState(true);
  const [showSecondary, setShowSecondary] = useState(showSecondaryOnLoad);
  const [showTertiary, setShowTertiary] = useState(false);
  const [secondaryData, setSecondaryData] = useState<SecondaryNavItem[] | undefined>(undefined);
  const [tertiaryData, setTertiaryData] = useState<SecondaryNavItem[] | undefined>(undefined);
  const { searchedLocation: initialSearchedLocation } = useSearchedLocation();
  const [searchedLocation, setSearchedLocation] = useState(initialSearchedLocation);

  const { weddingLocation } = useWeddingLocation();
  const weddingLocationForNavLinks = getSearchLocationForBriefLocation(
    getBriefLocation(weddingLocation)
  );

  const [lastSearchedLocation, setLastSearchedLocation] =
    useState<LocationOrAllMarketsType | null>();
  const shouldSkipLP =
    typeof window !== 'undefined' &&
    !userIsGuest &&
    isDesktopV2() &&
    user?.marketplace_account?.has_inquired;

  useEffect(() => {
    setShowAnnouncement(!hideBanner);
  }, [hideBanner]);

  useEffectOnce(() => {
    setLastSearchedLocation(getSearchedLocation());
  });

  const handleLocationChange = () => {
    setSearchedLocation(getSearchedLocation());
  };

  useEffectOnce(() => {
    if (!initialSearchedLocation) {
      handleLocationChange();
    }

    window.addEventListener('SEARCHED_LOCATION_CHANGE', handleLocationChange);

    return (): void => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('SEARCHED_LOCATION_CHANGE', handleLocationChange);
      }
    };
  });

  useEffect(() => {
    const newShowSecondary = !disableSecondary && showSecondaryNav(userIsGuest);
    setShowSecondary(newShowSecondary);
    // weddingLocationForNavLinks can either be the actually set wedding location, or the location based on IP
    const locationToUse = shouldSkipLP
      ? searchedLocation || lastSearchedLocation || weddingLocationForNavLinks
      : searchedLocation;
    setSecondaryData(
      newShowSecondary
        ? getSecondaryNavItems(user, showInquiryJewel, locationToUse, shouldSkipLP)
        : undefined
    );
    // Only checking the location slugs is sufficient and prevents a "maximum update depth exceeded" error
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    disableSecondary,
    searchedLocation?.slug,
    userIsGuest,
    shouldSkipLP,
    lastSearchedLocation?.slug,
    showInquiryJewel,
    user,
    weddingLocationForNavLinks.slug,
  ]);

  useEffect(() => {
    const newShowTertiary = showTertiaryNav(userIsGuest);
    setShowTertiary(newShowTertiary);
    setTertiaryData(newShowTertiary ? getExploreVendorsDropdownItems(searchedLocation) : undefined);
  }, [userIsGuest, searchedLocation]);

  const navHeights = useMemo(() => {
    return getNavHeights(userIsGuest, showAnnouncement, showSecondary, showTertiary);
  }, [userIsGuest, showAnnouncement, showSecondary, showTertiary]);

  const handleInitNavData = useCallback(() => {
    initNavData({
      userContext: user,
      secondaryData,
      tertiaryData,
      activePrimaryLink,
      activeSecondaryLink,
      activeTertiaryLink,
      disablePrimaryNavCollapse,
      hideBanner,
    });
    publishFeatureFlagOverrides(FLAGS, FeatureFlagsProductType.WEB_MARKETPLACE);
  }, [
    user,
    secondaryData,
    tertiaryData,
    activePrimaryLink,
    activeSecondaryLink,
    activeTertiaryLink,
    disablePrimaryNavCollapse,
    hideBanner,
  ]);

  useEffect(() => {
    window.addEventListener(NAV_LOADED, handleInitNavData);

    return () => {
      window.removeEventListener(NAV_LOADED, handleInitNavData);
    };
  }, [handleInitNavData]);

  useEffect(() => {
    handleInitNavData();
  }, [user, secondaryData, handleInitNavData]);

  // The DOM structure in the root-nav container should match what's in the index.html of web-nav
  // The key in the skeleton loader is necessary to prevent an error when replacing the nav HTML (see https://stackoverflow.com/a/54644168)
  return (
    <Fragment>
      {!ssr && (
        <Head>
          <link rel="stylesheet" href={navStyle} />
          <script async src={navScript} />
        </Head>
      )}
      {process.env.NODE_ENV !== 'test' && (
        <style
          dangerouslySetInnerHTML={{
            __html: `
            .${styles.navContainer} {
              height: ${navHeights.mobile}px;
            }
            ${MEDIA_QUERY.DESKTOP} {
              .${styles.navContainer} {
                height: ${navHeights.desktop}px;
              }
            }
          `.replace(/\s+/g, ' '),
          }}
        />
      )}
      <div className={cx(styles.navContainer, { [styles.postAuth]: !userIsGuest })}>
        {ssr ? (
          <div
            id="root-nav-container"
            data-testid="root-nav"
            dangerouslySetInnerHTML={{ __html: navHtml || '' }}
          >
            {children}
          </div>
        ) : (
          <Fragment>
            <div id="root-nav" data-testid="root-nav">
              <div key="PreauthNavSkeletonLoader">
                <PreauthNavSkeletonLoader
                  showPromoBar={showAnnouncement}
                  showSecondaryRow={!disableSecondary}
                />
              </div>
              {children}
            </div>
          </Fragment>
        )}
        <div id="notification-root" data-testid="notification-root" />
      </div>
    </Fragment>
  );
};

export default Nav;
