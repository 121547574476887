import { Fragment, useMemo } from 'react';

import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';

import cx from 'classnames';

import { hideSubNav } from '~/actions/vendors/vendorMenuActions';
import { useModal } from '~/contexts/ModalContext';
import { useAppDispatch } from '~/reducers';

import { NavLogo } from '../NavLogo';
import { NavMenuProps } from '../types';
import { useNavigationLinks } from '../useNavigationLinks';
import { AccountDropdown } from './AccountDropdown';
import { DesktopNavItem } from './DesktopNavItem';

import styles from './desktopNav.module.less';
import linkStyles from './desktopNavItem.module.less';

export const DesktopNav = (props: NavMenuProps): JSX.Element => {
  const { isAuthenticated, hasStorefront, showVendorLoginModal, hideNavLinksAndMenu, showSignup } =
    props;
  const links = useNavigationLinks();
  const { showAuthModal } = useModal();

  const items = useMemo(() => {
    return (
      <ul className={styles.navItems}>
        {links.map((link, i) => {
          return (
            <li
              className={styles.navItem}
              key={link.to}
              id={link.id}
              aria-hidden
              data-testid={link.dataTestId}
            >
              {/* // Position is 1 index, and the logo is the first thing in the list, so add 1 (1 for the 1-index, 1 for the logo) */}
              <DesktopNavItem trackingPosition={i + 2} item={link} />
            </li>
          );
        })}
      </ul>
    );
  }, [links]);

  const dispatch = useAppDispatch();

  return (
    <div
      className={cx(styles.desktopNav, { [styles.hideNonDesktop]: isAuthenticated })}
      id="desktop-nav"
    >
      <div className={styles.navLogo}>
        <NavLogo
          className={styles.navLogoLink}
          isAuthenticated={isAuthenticated}
          trackingProps={{
            navigation_level_1: 'VENDOR_LOGO',
            navigation_level_1_position: 1,
          }}
        />
      </div>
      {!hideNavLinksAndMenu && (
        <Fragment>
          <div className={styles.navLeft} onMouseLeave={() => dispatch(hideSubNav())}>
            {hasStorefront && items}
          </div>
          <div className={styles.navRight}>
            {isAuthenticated ? (
              <AccountDropdown trackingPosition={links.length + 2} />
            ) : (
              <ButtonV3
                className={linkStyles.loginBtn}
                variant="secondary"
                onClick={showVendorLoginModal}
                data-testid="vendor-login"
              >
                Log in
              </ButtonV3>
            )}
            {showSignup && (
              <ButtonV3
                className={linkStyles.signupBtn}
                variant="transparent"
                onClick={() => showAuthModal('VENDOR_SIGNUP')}
              >
                Get started
              </ButtonV3>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
};
