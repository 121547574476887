import {
  BANDS_DJS_TAXONOMY_KEY,
  BAR_SERVICES_TAXONOMY_KEY,
  CAKES_DESSERTS_TAXONOMY_KEY,
  CATERING_TAXONOMY_KEY,
  EXTRAS_TAXONOMY_KEY,
  FLORISTS_TAXONOMY_KEY,
  HAIR_MAKEUP_TAXONOMY_KEY,
  PHOTOGRAPHERS_TAXONOMY_KEY,
  PLANNERS_TAXONOMY_KEY,
  VENUES_TAXONOMY_KEY,
  VIDEOGRAPHERS_TAXONOMY_KEY,
  SearchableVendorTaxonomyKey,
  OFFICIANTS_TAXONOMY_KEY,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';

import Facets from '~/meta/facets';
import { FacetParentKey } from '~/types/facets';

import { PriceRange, PriceFieldTypes } from '../Storefront/editPages/VendorPricing/types';

export const LEAD_PREFERENCE_FACETS: Record<
  SearchableVendorTaxonomyKey,
  Record<string, { id: number; key: FacetParentKey }>
> = {
  [VENUES_TAXONOMY_KEY]: {
    TARGET_COUPLE_VENUE_PREFERENCE: Facets.TARGET_COUPLE_VENUE_PREFERENCE,
    VENUE_SETTING: Facets.VENUE_SETTING,
    EVENT_TYPES: Facets.EVENT_TYPES,
    PLANNER_REQUIRED: Facets.PLANNER_REQUIRED,
    VENUE_SERVICE_LEVEL: Facets.VENUE_SERVICE_LEVEL,
  },
  [PHOTOGRAPHERS_TAXONOMY_KEY]: {
    TARGET_COUPLE_VENUE_PREFERENCE: Facets.TARGET_COUPLE_VENUE_PREFERENCE,
    PERSONALITY: Facets.PERSONALITY,
    PHOTOGRAPHY_STYLE: Facets.PHOTOGRAPHY_STYLE,
  },
  [VIDEOGRAPHERS_TAXONOMY_KEY]: {
    TARGET_COUPLE_VENUE_PREFERENCE: Facets.TARGET_COUPLE_VENUE_PREFERENCE,
    PERSONALITY: Facets.PERSONALITY,
    VIDEOGRAPHY_STYLE: Facets.VIDEOGRAPHY_STYLE,
  },
  [FLORISTS_TAXONOMY_KEY]: {
    TARGET_COUPLE_VENUE_PREFERENCE: Facets.TARGET_COUPLE_VENUE_PREFERENCE,
    FLORIST_STYLE: Facets.FLORIST_STYLE,
    // @ts-expect-error this facet was renamed
    FLORIST_BUSINESS_ETHICS: Facets.FLORIST_BUSINESS_ETHICS,
  },
  [CATERING_TAXONOMY_KEY]: {
    TARGET_COUPLE_VENUE_PREFERENCE: Facets.TARGET_COUPLE_VENUE_PREFERENCE,
    EVENT_TYPES: Facets.EVENT_TYPES,
  },
  [BAR_SERVICES_TAXONOMY_KEY]: {
    TARGET_COUPLE_VENUE_PREFERENCE: Facets.TARGET_COUPLE_VENUE_PREFERENCE,
    EVENT_TYPES: Facets.EVENT_TYPES,
  },
  [BANDS_DJS_TAXONOMY_KEY]: {
    MUSICIAN_VIBE: Facets.MUSICIAN_VIBE,
    EVENT_TYPES: Facets.EVENT_TYPES,
  },
  [HAIR_MAKEUP_TAXONOMY_KEY]: {
    HAIR_BEAUTY_STYLE: Facets.HAIR_BEAUTY_STYLE,
  },
  [CAKES_DESSERTS_TAXONOMY_KEY]: {
    BAKER_STYLE: Facets.BAKERS_DECORATIONS,
  },
  [PLANNERS_TAXONOMY_KEY]: {
    PERSONALITY_TRAIT: Facets.PERSONALITY_TRAIT,
  },
  [OFFICIANTS_TAXONOMY_KEY]: {
    PERSONALITY_TRAIT: Facets.PERSONALITY_TRAIT,
    OFFICIANT_CEREMONY_TYPES: Facets.OFFICIANT_CEREMONY_TYPES,
    OFFICIANT_RELIGIOUS_AFFILIATIONS: Facets.OFFICIANT_RELIGIOUS_AFFILIATIONS,
  },
  [EXTRAS_TAXONOMY_KEY]: {
    PERSONALITY_TRAIT: Facets.PERSONALITY_TRAIT,
    EXTRAS_VENDOR_TYPE: Facets.EXTRAS_VENDOR_TYPE,
    EXTRAS_SERVICES: Facets.EXTRAS_SERVICES,
  },
};

export const FACET_SYMBOLS = {
  TARGET_COUPLE_VENUE_PREFERENCE: 'TARGET_COUPLE_VENUE_PREFERENCE',
  VENUE_SETTING: 'VENUE_SETTING',
  VENUE_SERVICE_LEVEL: 'VENUE_SERVICE_LEVEL',
  EVENT_TYPES: 'EVENT_TYPES',
  PLANNER_REQUIRED: 'PLANNER_REQUIRED',
  PERSONALITY: 'PERSONALITY',
  PHOTOGRAPHY_STYLE: 'PHOTOGRAPHY_STYLE',
  VIDEOGRAPHY_STYLE: 'VIDEOGRAPHY_STYLE',
  FLORIST_STYLE: 'FLORIST_STYLE',
  FLORIST_BUSINESS_ETHICS: 'FLORIST_BUSINESS_ETHICS',
  MUSICIAN_VIBE: 'MUSICIAN_VIBE',
  HAIR_BEAUTY_STYLE: 'HAIR_BEAUTY_STYLE',
  BAKER_STYLE: 'BAKER_STYLE',
};

export const TARGET_COUPLE_BUDGET: PriceRange = {
  type: PriceFieldTypes.RANGE,
  legend: 'How much does your average couple typically spend with you?',
  inputs: {
    min: {
      name: 'targetCoupleBudgetMin',
      ariaLabel: 'min target couple budget range',
      selector: () => null,
      persistance: {
        object: 'Storefront',
        field: 'targetCoupleBudgetMin',
        // is not converted, its mapped in the server
      },
    },
    max: {
      name: 'targetCoupleBudgetMax',
      ariaLabel: 'max target couple budget range',
      selector: () => null,
      persistance: {
        object: 'Storefront',
        field: 'targetCoupleBudgetMax',
        // is not converted, its mapped in the server
      },
    },
  },
  required: true,
};

export const TARGET_COUPLE_PER_PERSON_BUDGET: PriceRange = {
  type: PriceFieldTypes.RANGE,
  legend: 'How much does your average couple typically spend with you per person?',
  toolTip: {
    id: 'target-per-person-budget-tool-tip',
    text: 'Optional! If this number isn’t helpful to you, feel free to leave it blank.',
  },
  inputs: {
    min: {
      name: 'targetPerPersonBudgetMin',
      ariaLabel: 'min target per person budget range',
      selector: () => null,
      persistance: {
        object: 'Storefront',
        field: 'targetPerPersonBudgetMin',
        // is not converted, its mapped in the server
      },
    },
    max: {
      name: 'targetPerPersonBudgetMax',
      ariaLabel: 'max target per person budget range',
      selector: () => null,
      persistance: {
        object: 'Storefront',
        field: 'targetPerPersonBudgetMax',
        // is not converted, its mapped in the server
      },
    },
  },
  required: false,
};

export const TARGET_COUPLE_FULL_WEDDING_BUDGET: PriceRange = {
  type: PriceFieldTypes.RANGE,
  legend: 'On average, what is the total wedding budget of couples you typically work with?',
  toolTip: {
    id: 'target-couple-full-budget-tool-tip',
    text: 'Optional! If this number isn’t helpful to you, feel free to leave it blank.',
  },
  inputs: {
    min: {
      name: 'targetCoupleFullBudgetMin',
      ariaLabel: 'min target couple full wedding budget range',
      selector: () => null,
      persistance: {
        object: 'Storefront',
        field: 'targetCoupleFullBudgetMin',
        // is not converted, its mapped in the server
      },
    },
    max: {
      name: 'targetCoupleFullBudgetMax',
      ariaLabel: 'max target couple full wedding budget range',
      selector: () => null,
      persistance: {
        object: 'Storefront',
        field: 'targetCoupleFullBudgetMax',
        // is not converted, its mapped in the server
      },
    },
  },
  required: false,
};
