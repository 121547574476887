import {
  BANDS_DJS_TAXONOMY_KEY,
  BAR_SERVICES_TAXONOMY_KEY,
  CAKES_DESSERTS_TAXONOMY_KEY,
  CATERING_TAXONOMY_KEY,
  EXTRAS_TAXONOMY_KEY,
  FLORISTS_TAXONOMY_KEY,
  HAIR_MAKEUP_TAXONOMY_KEY,
  OFFICIANTS_TAXONOMY_KEY,
  PHOTOGRAPHERS_TAXONOMY_KEY,
  PLANNERS_TAXONOMY_KEY,
  SearchableVendorTaxonomyKey,
  VENUES_TAXONOMY_KEY,
  VIDEOGRAPHERS_TAXONOMY_KEY,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { getVendorTaxonomySwitchFunc } from '@zola-helpers/client/dist/es/marketplace/vendorUtils';

import Facets from '~/meta/facets';
import { FacetParentKey, FacetChildKey } from '~/types/facets';

export const PACKAGES_AND_SERVICES_FORM_KEY = 'packages';
export const DELIVERABLES_FORM_KEY = 'deliverables';
export const PERSONALITY_TRAIT_ROOT_ID = 825;

export const FLORIST_SERVICE_LEVELS_PARENT_KEY = 'florist-service-level';
export const FLORIST_PRE_WEDDING_SERVICES_FORM_KEY = 'preweddingServices';
export const FLORIST_DAY_OF_SERVICES_FORM_KEY = 'duringweddingServices';
export const FLORIST_POST_WEDDING_SERVICES_FORM_KEY = 'postweddingServices';
export const FLORIST_ARRANGEMENT_TYPES_FORM_KEY = 'floralArrangementTypes';
export const FLORIST_FLORAL_ARRANGEMENT_FORM_KEY = 'floralArrangements';

export type ServicesOfferedMeta = {
  title: string;
  parentId: number;
  formKey: string;
  parentKey: FacetParentKey;
  key?: FacetParentKey;
  singleSelect?: boolean;
  conditionalOn?: {
    parentKey: FacetParentKey;
    selectParentFacetKey: FacetChildKey | FacetParentKey;
  };
};

export const getServicesOfferedMetaForVendorTaxonomyKey = getVendorTaxonomySwitchFunc<
  SearchableVendorTaxonomyKey,
  ServicesOfferedMeta[]
>({
  [VENUES_TAXONOMY_KEY]: [
    {
      title: 'Amenities',
      parentId: Facets.SERVICES_OFFERED.id,
      formKey: 'amenities',
      parentKey: Facets.SERVICES_OFFERED.key,
    },
    {
      title: 'Parking & Transportation',
      parentId: Facets.TRANSPORTATION_SERVICES.id,
      formKey: 'transportation',
      parentKey: Facets.TRANSPORTATION_SERVICES.key,
    },
    {
      title: 'Rentals',
      parentId: Facets.RENTAL_SERVICES.id,
      formKey: 'rentals',
      parentKey: Facets.RENTAL_SERVICES.key,
    },
    {
      title: 'Accommodations',
      parentId: Facets.ACCOMMODATIONS_SERVICES.id,
      formKey: 'accommodations',
      parentKey: Facets.ACCOMMODATIONS_SERVICES.key,
    },
  ],
  [PHOTOGRAPHERS_TAXONOMY_KEY]: [
    {
      title: 'Packages & Services',
      parentId: Facets.PACKAGE_COMPONENTS_SERVICES_OFFERED.id,
      formKey: PACKAGES_AND_SERVICES_FORM_KEY,
      parentKey: Facets.PACKAGE_COMPONENTS_SERVICES_OFFERED.key,
    },
    {
      title: 'Deliverables',
      parentId: Facets.PACKAGE_DELIVERABLES_OFFERED.id,
      formKey: DELIVERABLES_FORM_KEY,
      parentKey: Facets.PACKAGE_DELIVERABLES_OFFERED.key,
    },
    {
      title: 'Photo Formats',
      parentId: Facets.PHOTO_FORMAT.id,
      formKey: 'formats',
      parentKey: Facets.PHOTO_FORMAT.key,
    },
  ],
  [FLORISTS_TAXONOMY_KEY]: [
    {
      title: 'Service Levels',
      parentId: Facets.FLORIST_SERVICE_LEVEL.id,
      parentKey: Facets.FLORIST_SERVICE_LEVEL.key,
      formKey: 'serviceLevels',
    },
    {
      title: 'Pre-Wedding Services',
      parentId: Facets.FLORIST_SERVICES_PRE_WEDDING.id,
      parentKey: Facets.FLORIST_SERVICES_PRE_WEDDING.key,
      formKey: 'preweddingServices',
    },
    {
      title: 'Day-Of Services',
      parentId: Facets.FLORIST_SERVICES_DURING_WEDDING.id,
      parentKey: Facets.FLORIST_SERVICES_DURING_WEDDING.key,
      formKey: 'duringweddingServices',
    },
    {
      title: 'Post-Wedding Services',
      parentId: Facets.FLORIST_SERVICES_POST_WEDDING.id,
      parentKey: Facets.FLORIST_SERVICES_POST_WEDDING.key,
      formKey: 'postweddingServices',
    },
    {
      title: 'Arrangement Styles',
      parentId: Facets.FLORIST_ARRANGEMENT_TYPE.id,
      parentKey: Facets.FLORIST_ARRANGEMENT_TYPE.key,
      formKey: 'floralArrangementTypes',
    },
    {
      title: 'Floral Arrangements',
      parentId: Facets.FLORIST_FLORAL_ARRANGEMENT.id,
      parentKey: Facets.FLORIST_FLORAL_ARRANGEMENT.key,
      formKey: 'floralArrangements',
    },
  ],
  [VIDEOGRAPHERS_TAXONOMY_KEY]: [
    {
      title: 'Packages & Services',
      parentId: Facets.VIDEOGRAPHER_PACKAGE_COMPONENTS.id,
      formKey: PACKAGES_AND_SERVICES_FORM_KEY,
      parentKey: Facets.VIDEOGRAPHER_PACKAGE_COMPONENTS.key,
    },
    {
      title: 'Deliverables',
      parentId: Facets.VIDEOGRAPHER_DELIVERABLES.id,
      formKey: DELIVERABLES_FORM_KEY,
      parentKey: Facets.VIDEOGRAPHER_DELIVERABLES.key,
    },
  ],
  [CATERING_TAXONOMY_KEY]: [
    {
      title: 'Menu Types',
      parentId: Facets.MENU_TYPES.id,
      formKey: 'menuTypes',
      parentKey: Facets.MENU_TYPES.key,
    },
    {
      title: 'Drink Types',
      parentId: Facets.CATERER_DRINK_TYPES.id,
      formKey: 'catererDrinkTypes',
      parentKey: Facets.CATERER_DRINK_TYPES.key,
    },
    {
      title: 'General Services',
      parentId: Facets.CATERER_GENERAL_SERVICES.id,
      formKey: 'catererGeneralServices',
      parentKey: Facets.CATERER_GENERAL_SERVICES.key,
    },
    {
      title: 'Beverage Services',
      parentId: Facets.BEVERAGES_RELATED_SERVICES.id,
      formKey: 'beveragesRelatedServices',
      parentKey: Facets.BEVERAGES_RELATED_SERVICES.key,
    },
    {
      title: 'Meal Types',
      parentId: Facets.MENU_FORMATS_OFFERED.id,
      formKey: 'menuFormatsOffered',
      parentKey: Facets.MENU_FORMATS_OFFERED.key,
    },
    {
      title: 'Cuisine Types',
      parentId: Facets.CATERERS_CUISINE_TYPES.id,
      formKey: 'caterersCuisineTypes',
      parentKey: Facets.CATERERS_CUISINE_TYPES.key,
    },
    {
      title: 'Dietary Accommodations',
      parentId: Facets.CATERER_DIETARY_RESTRICTIONS.id,
      formKey: 'catererDietaryRestrictions',
      parentKey: Facets.CATERER_DIETARY_RESTRICTIONS.key,
    },
  ],
  [BAR_SERVICES_TAXONOMY_KEY]: [
    {
      title: 'Menu Types',
      parentId: Facets.MENU_TYPES.id,
      formKey: 'menuTypes',
      parentKey: Facets.MENU_TYPES.key,
    },
    {
      title: 'Drink Types',
      parentId: Facets.CATERER_DRINK_TYPES.id,
      formKey: 'catererDrinkTypes',
      parentKey: Facets.CATERER_DRINK_TYPES.key,
    },
    {
      title: 'General Services',
      parentId: Facets.CATERER_GENERAL_SERVICES.id,
      formKey: 'catererGeneralServices',
      parentKey: Facets.CATERER_GENERAL_SERVICES.key,
    },
    {
      title: 'Beverage Services',
      parentId: Facets.BEVERAGES_RELATED_SERVICES.id,
      formKey: 'beveragesRelatedServices',
      parentKey: Facets.BEVERAGES_RELATED_SERVICES.key,
    },
  ],
  [BANDS_DJS_TAXONOMY_KEY]: [
    {
      title: 'Musician Category',
      parentId: Facets.MUSICIAN_TYPE.id,
      parentKey: Facets.MUSICIAN_TYPE.key,
      formKey: 'musicianType',
      singleSelect: true,
    },
    {
      title: 'Musician Type',
      parentId: Facets.MUSIC_DJS.id,
      parentKey: Facets.MUSIC_DJS.key,
      formKey: 'musicDjs',
      singleSelect: true,
      conditionalOn: {
        parentKey: Facets.MUSICIAN_TYPE.key,
        selectParentFacetKey: 'musician-type-dj',
      },
    },
    {
      title: 'Musician Type',
      parentId: Facets.MUSICIAN_CATEGORY.id,
      parentKey: Facets.MUSICIAN_CATEGORY.key,
      formKey: 'musicCategory',
      conditionalOn: {
        parentKey: Facets.MUSICIAN_TYPE.key,
        selectParentFacetKey: 'musician-type-ceremony-only-musician',
      },
    },
    {
      title: 'Musician Type',
      parentId: Facets.MUSIC_SOLO_ACTS.id,
      parentKey: Facets.MUSIC_SOLO_ACTS.key,
      formKey: 'musicSolo',
      singleSelect: true,
      conditionalOn: {
        parentKey: Facets.MUSICIAN_TYPE.key,
        selectParentFacetKey: 'musician-type-live-solo-performer',
      },
    },

    {
      title: 'Musician Type',
      parentId: Facets.MUSIC_BAND_ACTS.id,
      parentKey: Facets.MUSIC_BAND_ACTS.key,
      formKey: 'musicBand',
      singleSelect: true,
      conditionalOn: {
        parentKey: Facets.MUSICIAN_TYPE.key,
        selectParentFacetKey: 'musician-type-live-band-or-ensemble',
      },
    },
    {
      title: 'Event Type',
      parentId: Facets.EVENT_TYPES.id,
      parentKey: Facets.EVENT_TYPES.key,
      formKey: 'eventTypes',
    },
    {
      title: 'Your Musical Genres',
      parentId: Facets.MUSIC_GENRE.id,
      parentKey: Facets.MUSIC_GENRE.key,
      formKey: 'musicGenres',
    },
    {
      title: 'Your Services Inclusive Of General Pricing',
      parentId: Facets.MUSICIAN_SERVICES.id,
      parentKey: Facets.MUSICIAN_SERVICES.key,
      formKey: 'musicianServices',
    },
  ],
  [CAKES_DESSERTS_TAXONOMY_KEY]: [
    {
      title: 'Dessert Types',
      parentId: Facets.BAKERS_PRODUCT_TYPES.id,
      parentKey: Facets.BAKERS_PRODUCT_TYPES.key,
      formKey: 'bakersProductTypes',
    },
    {
      title: 'Cake Customizations',
      parentId: Facets.BAKERS_CAKE_CUSTOMIZATIONS.id,
      parentKey: Facets.BAKERS_CAKE_CUSTOMIZATIONS.key,
      formKey: 'bakersCakeCustomizations',
    },
    {
      title: 'Decor Options',
      parentId: Facets.BAKERS_DECORATIONS.id,
      parentKey: Facets.BAKERS_DECORATIONS.key,
      formKey: 'bakersDecorations',
    },
    {
      title: 'Dietary Accommodations',
      parentId: Facets.BAKERS_DIETARY_ACCOMMODATIONS.id,
      parentKey: Facets.BAKERS_DIETARY_ACCOMMODATIONS.key,
      formKey: 'bakersDietaryAccommodations',
    },
    {
      title: 'Services',
      parentId: Facets.BAKERS_SERVICES.id,
      parentKey: Facets.BAKERS_SERVICES.key,
      formKey: 'bakersServices',
    },
  ],
  [HAIR_MAKEUP_TAXONOMY_KEY]: [
    {
      title: 'Service Categories',
      parentId: Facets.HAIR_BEAUTY_SERVICE_CATEGORY.id,
      parentKey: Facets.HAIR_BEAUTY_SERVICE_CATEGORY.key,
      formKey: 'hairBeautyServiceCategory',
    },
    {
      title: 'Styles',
      parentId: Facets.HAIR_BEAUTY_STYLE.id,
      parentKey: Facets.HAIR_BEAUTY_STYLE.key,
      formKey: 'hairBeautyStyles',
    },
    {
      title: 'General Beauty Services',
      parentId: Facets.BEAUTY_SERVICE_GENERAL.id,
      parentKey: Facets.BEAUTY_SERVICE_GENERAL.key,
      formKey: 'generalBeautyServices',
    },
    {
      title: 'Hair Services',
      parentId: Facets.BEAUTY_SERVICE_HAIR.id,
      parentKey: Facets.BEAUTY_SERVICE_HAIR.key,
      formKey: 'hairBeautyServices',
      conditionalOn: {
        parentKey: Facets.HAIR_BEAUTY_SERVICE_CATEGORY.key,
        selectParentFacetKey: 'hair-beauty-service-category-hair',
      },
    },
    {
      title: 'Henna Services',
      parentId: Facets.BEAUTY_SERVICE_HENNA.id,
      parentKey: Facets.BEAUTY_SERVICE_HENNA.key,
      formKey: 'hennaBeautyServices',
      conditionalOn: {
        parentKey: Facets.HAIR_BEAUTY_SERVICE_CATEGORY.key,
        selectParentFacetKey: 'hair-beauty-service-category-henna-art',
      },
    },
    {
      title: 'Spa Services',
      parentId: Facets.BEAUTY_SERVICE_SPA.id,
      parentKey: Facets.BEAUTY_SERVICE_SPA.key,
      formKey: 'spaBeautyServices',
      conditionalOn: {
        parentKey: Facets.HAIR_BEAUTY_SERVICE_CATEGORY.key,
        selectParentFacetKey: 'hair-beauty-service-category-spa-treatments',
      },
    },
    {
      title: 'Makeup Services',
      parentId: Facets.BEAUTY_SERVICE_MAKEUP.id,
      parentKey: Facets.BEAUTY_SERVICE_MAKEUP.key,
      formKey: 'makeupBeautyServices',
      conditionalOn: {
        parentKey: Facets.HAIR_BEAUTY_SERVICE_CATEGORY.key,
        selectParentFacetKey: 'hair-beauty-service-category-makeup',
      },
    },
    {
      title: 'Styling Services',
      parentId: Facets.BEAUTY_SERVICE_STYLING.id,
      parentKey: Facets.BEAUTY_SERVICE_STYLING.key,
      formKey: 'stylingBeautyServices',
      conditionalOn: {
        parentKey: Facets.HAIR_BEAUTY_SERVICE_CATEGORY.key,
        selectParentFacetKey: 'hair-beauty-service-category-styling',
      },
    },
    {
      title: 'Fitness/Wellness Services',
      parentId: Facets.BEAUTY_SERVICE_FITNESS.id,
      parentKey: Facets.BEAUTY_SERVICE_FITNESS.key,
      formKey: 'fitnessWellnessBeautyServices',
      conditionalOn: {
        parentKey: Facets.HAIR_BEAUTY_SERVICE_CATEGORY.key,
        selectParentFacetKey: 'hair-beauty-service-category-fitness-wellness',
      },
    },
  ],
  // Planners use planning levels instead of services provided
  [PLANNERS_TAXONOMY_KEY]: [] as ServicesOfferedMeta[],
  [OFFICIANTS_TAXONOMY_KEY]: [
    {
      title: 'Ceremony Types',
      parentId: Facets.OFFICIANT_CEREMONY_TYPES.id,
      parentKey: Facets.OFFICIANT_CEREMONY_TYPES.key,
      formKey: 'officiantCeremonyTypes',
    },
    {
      title: 'Religious Affiliations',
      parentId: Facets.OFFICIANT_RELIGIOUS_AFFILIATIONS.id,
      parentKey: Facets.OFFICIANT_RELIGIOUS_AFFILIATIONS.key,
      formKey: 'officiantReligiousAffiliations',
    },
  ],
  [EXTRAS_TAXONOMY_KEY]: [
    {
      title: 'Services You Offer',
      parentId: Facets.EXTRAS_SERVICES.id,
      parentKey: Facets.EXTRAS_SERVICES.key,
      formKey: 'extrasServices',
    },
  ],
});
