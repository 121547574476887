import type { UserContext } from '@zola-helpers/client/dist/es/@types';
import {
  SecondaryNavItem,
  PrimaryNavIdentifier,
  SecondaryNavIdentifier,
  TertiaryNavIdentifier,
} from '@zola-helpers/client/dist/es/constants/navConstants';
import { vendorSearchUrl } from '@zola-helpers/client/dist/es/marketplace/vendorSearchUtils';
import {
  BANDS_DJS_TAXONOMY_KEY,
  BAR_SERVICES_TAXONOMY_KEY,
  CAKES_DESSERTS_TAXONOMY_KEY,
  CATERING_TAXONOMY_KEY,
  FLORISTS_TAXONOMY_KEY,
  HAIR_MAKEUP_TAXONOMY_KEY,
  PHOTOGRAPHERS_TAXONOMY_KEY,
  PLANNERS_TAXONOMY_KEY,
  SearchableVendorTaxonomyKey,
  VENUES_TAXONOMY_KEY,
  VIDEOGRAPHERS_TAXONOMY_KEY,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';

import { LocationOrAllMarketsType } from '~/types/responseTypes';
import { vendorCategoryLandingUrl, VENDOR_SEARCH_LANDING_PAGE_URL } from '~/util/vendorSearchUrl';

const whichUrl = (
  taxonomyKey: SearchableVendorTaxonomyKey,
  includedLocation: LocationOrAllMarketsType | null,
  shouldSkipLP?: boolean | null
) => {
  if (shouldSkipLP || includedLocation) {
    return vendorSearchUrl(taxonomyKey, includedLocation?.slug);
  }

  return vendorCategoryLandingUrl(taxonomyKey);
};

export const getWeddingVenuesDropdownItems = (
  includedLocation: LocationOrAllMarketsType | null = null
): SecondaryNavItem[] => {
  return [
    {
      id: TertiaryNavIdentifier.VENUES,
      title: 'Outdoor venues',
      path: vendorSearchUrl(VENUES_TAXONOMY_KEY, includedLocation?.slug, ['outdoor-space']),
      businessCategory: 'VENUE',
      businessUnit: 'MARKETPLACE',
    },
    {
      id: TertiaryNavIdentifier.VENUES,
      title: 'Intimate venues',
      path: vendorSearchUrl(VENUES_TAXONOMY_KEY, includedLocation?.slug, [], {
        'capacity-max': '50',
        'capacity-min': '0',
      }),
      businessCategory: 'VENUE',
      businessUnit: 'MARKETPLACE',
    },
    {
      id: TertiaryNavIdentifier.VENUES,
      title: 'Beach and waterfront venues',
      path: vendorSearchUrl(VENUES_TAXONOMY_KEY, includedLocation?.slug, ['beaches-waterfronts']),
      businessCategory: 'VENUE',
      businessUnit: 'MARKETPLACE',
    },
    {
      id: TertiaryNavIdentifier.VENUES,
      title: 'Barn venues',
      path: vendorSearchUrl(VENUES_TAXONOMY_KEY, includedLocation?.slug, ['farms-barns-ranches']),
      businessCategory: 'VENUE',
      businessUnit: 'MARKETPLACE',
    },
    {
      id: TertiaryNavIdentifier.VENUES,
      title: 'Estate venues',
      path: vendorSearchUrl(VENUES_TAXONOMY_KEY, includedLocation?.slug, [
        'historic-estates-mansions',
      ]),
      businessCategory: 'VENUE',
      businessUnit: 'MARKETPLACE',
    },
    {
      id: TertiaryNavIdentifier.VENUES,
      title: 'Vineyard venues',
      path: vendorSearchUrl(VENUES_TAXONOMY_KEY, includedLocation?.slug, ['vineyards-wineries']),
      businessCategory: 'VENUE',
      businessUnit: 'MARKETPLACE',
    },
    {
      id: TertiaryNavIdentifier.VENUES,
      title: 'All-inclusive venues',
      path: vendorSearchUrl(VENUES_TAXONOMY_KEY, includedLocation?.slug, ['all-inclusive']),
      businessCategory: 'VENUE',
      businessUnit: 'MARKETPLACE',
    },
    {
      id: TertiaryNavIdentifier.VENUES,
      title: 'Rehearsal dinner venues',
      path: vendorSearchUrl(VENUES_TAXONOMY_KEY, includedLocation?.slug, ['rehearsal-dinner']),
      businessCategory: 'VENUE',
      businessUnit: 'MARKETPLACE',
    },
    {
      id: TertiaryNavIdentifier.VENUES,
      title: 'Wedding shower venues',
      path: vendorSearchUrl(VENUES_TAXONOMY_KEY, includedLocation?.slug, ['bridal-showers']),
      businessCategory: 'VENUE',
      businessUnit: 'MARKETPLACE',
    },
  ];
};

export const getExploreVendorsDropdownItems = (
  includedLocation: LocationOrAllMarketsType | null = null,
  shouldSkipLP?: boolean | null
): SecondaryNavItem[] => {
  return [
    {
      id: TertiaryNavIdentifier.VENUES,
      title: 'Venues',
      path: whichUrl(VENUES_TAXONOMY_KEY, includedLocation, shouldSkipLP),
      businessCategory: 'VENUE',
      businessUnit: 'MARKETPLACE',
    },
    {
      id: TertiaryNavIdentifier.PHOTOGRAPHERS,
      title: 'Photographers',
      path: whichUrl(PHOTOGRAPHERS_TAXONOMY_KEY, includedLocation, shouldSkipLP),
      businessCategory: 'PHOTOGRAPHER',
      businessUnit: 'MARKETPLACE',
    },
    {
      id: TertiaryNavIdentifier.VIDEOGRAPHERS,
      title: 'Videographers',
      path: whichUrl(VIDEOGRAPHERS_TAXONOMY_KEY, includedLocation, shouldSkipLP),
      businessCategory: 'VIDEOGRAPHER',
      businessUnit: 'MARKETPLACE',
    },
    {
      id: TertiaryNavIdentifier.CATERING,
      title: 'Caterers',
      path: whichUrl(CATERING_TAXONOMY_KEY, includedLocation, shouldSkipLP),
      businessCategory: 'CATERER',
      businessUnit: 'MARKETPLACE',
    },
    {
      id: TertiaryNavIdentifier.FLORISTS,
      title: 'Florists',
      path: whichUrl(FLORISTS_TAXONOMY_KEY, includedLocation, shouldSkipLP),
      businessCategory: 'FLORIST',
      businessUnit: 'MARKETPLACE',
    },
    {
      id: TertiaryNavIdentifier.HAIR_MAKEUP,
      title: 'Beauty',
      path: whichUrl(HAIR_MAKEUP_TAXONOMY_KEY, includedLocation, shouldSkipLP),
      businessCategory: 'HAIR_MAKEUP',
      businessUnit: 'MARKETPLACE',
    },
    {
      id: TertiaryNavIdentifier.PLANNERS,
      title: 'Planners',
      path: whichUrl(PLANNERS_TAXONOMY_KEY, includedLocation, shouldSkipLP),
      businessCategory: 'PLANNER',
      businessUnit: 'MARKETPLACE',
    },
    {
      id: TertiaryNavIdentifier.BANDS_DJS,
      title: 'Bands and DJs',
      path: whichUrl(BANDS_DJS_TAXONOMY_KEY, includedLocation, shouldSkipLP),
      businessCategory: 'MUSICIAN_DJ',
      businessUnit: 'MARKETPLACE',
    },
    {
      id: TertiaryNavIdentifier.CAKES_DESSERTS,
      title: 'Cake and dessert',
      path: whichUrl(CAKES_DESSERTS_TAXONOMY_KEY, includedLocation, shouldSkipLP),
      businessCategory: 'CAKES_DESSERTS',
      businessUnit: 'MARKETPLACE',
    },
    {
      id: TertiaryNavIdentifier.BAR_SERVICES,
      title: 'Bar and beverage',
      path: whichUrl(BAR_SERVICES_TAXONOMY_KEY, includedLocation, shouldSkipLP),
      businessCategory: 'BAR_SERVICES',
      businessUnit: 'MARKETPLACE',
    },
  ];
};

export const getSecondaryNavItems = (
  userContext: UserContext | null = null,
  hasInquiryJewel = false,
  includedLocation: LocationOrAllMarketsType | null = null,
  shouldSkipLP?: boolean | null
): SecondaryNavItem[] => {
  const isUserContextLoaded = userContext?.is_guest !== undefined;
  const isGuest = userContext ? Boolean(userContext.is_guest) : true;

  if (!isUserContextLoaded) return [];

  const realWeddings: SecondaryNavItem = {
    id: SecondaryNavIdentifier.REAL_WEDDINGS,
    title: 'Real wedding inspiration',
    path: '/inspiration',
    parent: PrimaryNavIdentifier.VENDORS,
    businessCategory: 'UNATTRIBUTED',
    businessUnit: 'REAL_WEDDINGS',
  };

  const becomeVendor: SecondaryNavItem = {
    id: SecondaryNavIdentifier.BECOME_VENDOR,
    title: 'Become a vendor',
    path: 'https://www.zola.com/for-vendors/',
    parent: PrimaryNavIdentifier.VENDORS,
    businessCategory: 'UNATTRIBUTED',
    businessUnit: 'VENDOR_EXPERIENCE',
    target: '_blank',
  };

  const weddingVenues: SecondaryNavItem = {
    id: SecondaryNavIdentifier.WEDDING_VENUES,
    title: 'Wedding venues',
    path: whichUrl(VENUES_TAXONOMY_KEY, includedLocation, shouldSkipLP),
    showCaret: true,
    children: getWeddingVenuesDropdownItems(includedLocation),
    parent: PrimaryNavIdentifier.VENDORS,
    businessCategory: 'VENUE',
    businessUnit: 'MARKETPLACE',
  };

  const exploreVendors: SecondaryNavItem = {
    id: SecondaryNavIdentifier.EXPLORE_VENDORS,
    title: isGuest ? 'Vendors' : 'Explore vendors',
    path: shouldSkipLP
      ? whichUrl(VENUES_TAXONOMY_KEY, includedLocation)
      : VENDOR_SEARCH_LANDING_PAGE_URL,
    showCaret: true,
    children: getExploreVendorsDropdownItems(includedLocation, shouldSkipLP),
    parent: PrimaryNavIdentifier.VENDORS,
    businessCategory: 'UNATTRIBUTED',
    businessUnit: 'MARKETPLACE',
  };

  const vendorInquiries: SecondaryNavItem = {
    id: SecondaryNavIdentifier.VENDOR_CONNECTIONS,
    title: 'Inbox',
    showJewel: hasInquiryJewel,
    path: '/wedding-vendors/inquiries',
    parent: PrimaryNavIdentifier.VENDORS,
    businessCategory: 'UNATTRIBUTED',
    businessUnit: 'MARKETPLACE',
  };

  const bookedVendors: SecondaryNavItem = {
    id: SecondaryNavIdentifier.BOOKED_VENDORS,
    title: 'Booked vendors',
    path: '/wedding-vendors/inquiries/booked',
    parent: PrimaryNavIdentifier.VENDORS,
    businessCategory: 'UNATTRIBUTED',
    businessUnit: 'MARKETPLACE',
  };

  const favorites: SecondaryNavItem = {
    id: SecondaryNavIdentifier.YOUR_FAVORITES,
    title: 'Favorites',
    path: '/favorites/vendors',
    parent: PrimaryNavIdentifier.VENDORS,
    businessCategory: 'UNATTRIBUTED',
    businessUnit: 'MARKETPLACE',
  };

  if (isGuest) {
    return [weddingVenues, exploreVendors, realWeddings, becomeVendor];
  }

  return [exploreVendors, vendorInquiries, bookedVendors, favorites];
};

export default getSecondaryNavItems;
