import { ReactNode } from 'react';

import {
  VendorTaxonomyKey,
  SearchableVendorTaxonomyKey,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { VendorTypeEnum } from '@zola-helpers/client/dist/es/marketplace/vendorUtils';
import {
  AccountVendorView as BackendAccountVendorView,
  AddressView,
  BillingAddress,
  BudgetVendorSyncResolutionView,
  CatererStoryView as BackendCatererStoryView,
  // Danger, this view has some issues since it comes from the
  // order service, in particular, the address field
  CreditCardView as BackendCreditCardView,
  DebitBankAccountView as BackendDebitBankAccountView,
  EntityFacetView as BackendEntityFacetView,
  ExtrasView as BackendExtrasView,
  InquiryDraftCardView,
  InquiryPreferencesView,
  InquiryView,
  InspirationSearchResponse,
  OfficiantView as BackendOfficiantView,
  Payment,
  RelatedLinkView,
  ReviewResponseView,
  SearchLocationView,
  SrpDataView as BackendSrpDataView,
  StorefrontCardView,
  StorefrontResponseStatsView,
  TaxonomyNodeView as BackendTaxonomyNodeView,
  VendorCardView as BackendVendorCardView,
  WeddingPlannerView as BackendWeddingPlannerView,
  WeddingView,
} from '@zola/svc-marketplace-ts-types';

import { InquiryStatus } from '~/types/enums';

import { FacetChildKey, FacetParentKey } from './facets';
import type { Nullable, RemoveNonNullables, PartialRecord } from './utils';

export enum DurationUnit {
  NANOSECONDS = 'NANOSECONDS',
  MICROSECONDS = 'MICROSECONDS',
  MILLISECONDS = 'MILLISECONDS',
  SECONDS = 'SECONDS',
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
}

// From StorefrontView.PublicationStatusEnum
export enum StorefrontPublicationStatusEnum {
  /** has not been submitted for review yet */
  NEW = 'NEW',
  /** submitted by the vendor */
  SUBMITTED = 'SUBMITTED',
  /**  in review by a VSM */
  INREVIEW = 'IN_REVIEW',
  /** accepted by a VSM and published */
  ACCEPTED = 'ACCEPTED',
  CLOSED = 'CLOSED',
  /** There were problems with the review submission */
  REJECTED = 'REJECTED',
  SPAM = 'SPAM',
}

export interface VendorDetails {
  id: number;
  uuid: string;
  storefrontId?: number;
}

export interface VenueDetails extends VendorDetails {
  name?: string;
  title?: string | null;
  rooms: number | null;
  minCapacity: number | null;
  maxCapacity: number | null;
  parkingCapacity: number | null;
  busRvParkingCapacity: number | null;
  foodBeverageStartPriceCents: number | null;
  foodAndBeverageStartPriceCentsPerPerson: number | null;
  peakSpaceStartPriceCents: number | null;
  offPeakSpaceStartPriceCents: number | null;
  // @see VenueSpaceMapper
  spaces: VenueSpaceView[];
  // @see VenueMenuMapper
  menus: MappedVenueMenuView[];
}
export interface PhotographerDetails extends VendorDetails {
  storefrontId: number;
  elopementStartPriceCents: number | null;
  elopementEndPriceCents: number | null;
  /** @deprecated */
  priceCentsPerHour: null;
  /** @deprecated */
  priceCentsPerAlbum: null;
  /** @deprecated */
  priceCentsPerPrint: null;
  /** @deprecated */
  priceCentsPerDrone: null;
  workingStyle: number;
  // vendor name in bio
  name: string | null;
  title: string | null;
  bioPhotoImageId: string | null;
}

export interface FloristDetails extends VendorDetails {
  storefrontId: number;
  bioPhotoImageId: string | null;
  // vendor name in bio
  name: string | null;
  title: string | null;
  deliveryIncluded: boolean;
  workingStyle: number | null; // null? not null?
  /** Minimum spend for the whole wedding */
  startPriceCents: number | null;
  /** @deprecated */
  minimumSpendCents: number | null;
  bridalBouquetStartPriceCents: number | null;
  bridalBouquetEndPriceCents: number | null;
  lowCenterpieceStartPriceCents: number | null;
  lowCenterpieceEndPriceCents: number | null;
}

export interface VideographerDetails extends VendorDetails {
  storefrontId: number;
  bioPhotoImageId: string | null;
  // vendor name in bio
  name: string | null;
  title: string | null;
  elopementStartPriceCents: null;
  elopementEndPriceCents: null;
  workingStyleWithPhotographer: number | null;
}

export interface CatererDetails extends VendorDetails {
  storefrontId: number;
  bioPhotoImageId: string | null;
  name: string | null;
  title: string | null;
  /** Start price per person for alocholic beverage packages */
  alcoholPerPersonStartPriceCents: number | null;
  /** Starting price per-person for food */
  startPriceCentsPerPerson: number | null;
  /** Minimum spend */
  startPriceCents: number | null;
  minimumHeadCount: number | null;
  /** @deprecated Bar-services-only caterers have been migrated to the Bar Services & Beverages subcategory/taxonomy */
  barServicesOnly?: boolean;
}

export interface MusicianDetails extends VendorDetails {
  storefrontId: number;
  bioPhotoImageId: string | null;
  name: string | null;
  title: string | null;
  /** @deprecated - replaced with receptionStartPriceCents */
  startPriceCents: number | null;
  ceremonyStartPriceCents: number | null;
  receptionStartPriceCents: number | null;
  minimumHeadCount: null;
  actSize: number | null;
  actSizeMin: number | null;
  actSizeMax: number | null;
}

export interface BeauticianDetails extends VendorDetails {
  storefrontId: number;
  bioPhotoImageId: string | null;
  // vendor name in bio
  name: string | null;
  title: string | null;
  /** Minimum spend */
  startPriceCents: number | null;
  /** Starting price per person */
  startPriceCentsPerPerson: number | null;
  /** @deprecated - we try to use startPriceCents instead */
  minimumSpendCents: number | null;
  /** @deprecated - we stopped asking for this field */
  trialsStartPriceCents: number | null;
  minimumHeadCount: number | null;
  stylistCount: number | null;
}

export interface BakerDetails extends VendorDetails {
  storefrontId: number;
  bioPhotoImageId: string | null;
  name: string | null;
  title: string | null;
  /** @deprecated - This field doesn't exist in the db.  It was storing per person, it got renamed to per-person and we kept the api the same */
  startPriceCents: number | null;
  startPriceCentsPerPerson: number | null;
  minimumSpendCents: number | null;
  minimumHeadCount: number | null;
}

export interface WeddingPlannerDetails
  extends VendorDetails,
    Omit<BackendWeddingPlannerView, 'storefrontId' | 'id' | 'uuid'> {
  storefrontId: number;
}

export interface OfficiantDetails
  extends VendorDetails,
    Omit<BackendOfficiantView, 'id' | 'uuid' | 'storefrontId'> {
  storefrontId: number;
}

export interface ExtrasDetails
  extends VendorDetails,
    Omit<BackendExtrasView, 'id' | 'uuid' | 'storefrontId'> {
  storefrontId: number;
}

export type AnyVendorDetails =
  | BakerDetails
  | BeauticianDetails
  | CatererDetails
  | ExtrasDetails
  | FloristDetails
  | MusicianDetails
  | OfficiantDetails
  | PhotographerDetails
  | WeddingPlannerDetails
  | VenueDetails
  | VideographerDetails;

export enum MessageAttachmentTypeEnum {
  FILE = 'FILE',
  URL = 'URL',
}

export enum ParticipantTypeEnum {
  COUPLE = 'COUPLE',
  VENDOR = 'VENDOR',
}

export enum ReviewerTypeEnum {
  COUPLE = 'COUPLE',
  VENDOR = 'VENDOR',
}

export enum StorefrontLinkTypeEnum {
  FACEBOOK_VIDEO = 'FACEBOOK_VIDEO',
  VIMEO = 'VIMEO',
  YOUTUBE = 'YOUTUBE',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  PINTEREST = 'PINTEREST',
  TWITTER = 'TWITTER',
  WEB = 'WEB',
  MATTERPORT = 'MATTERPORT',
  SKETCHFAB = 'SKETCHFAB',
}

export enum LastMessageSentByParticipantTypeEnum {
  VENDOR = 'VENDOR',
  COUPLE = 'COUPLE',
}

// named CloseEventTypeEnum on backend
export type InquiryCloseEventEnum = InquiryView.CloseEventTypeEnum;

/**
 * Response when inquiries to other storefronts in the same company
 * are requested
 *
 * @see getOtherStorefrontInquiries
 */
export interface InquiryStorefrontSummaryView {
  inquiryUuid: string;
  /** When the inquiry was created as milliseconds since epoch */
  inquiryCreatedAt: number;
  storefrontUuid: string;
  storefrontName: string;
  storefrontSlug: string;
  inquiryStatus: InquiryStatus;
}

/**
 * A brief view of an inquiry that has unread messages for the logged
 * in couple or vendor.
 */
export interface ActionableInquiryView {
  /* Inquiry UUID */
  uuid: string;
  /** The inquiry's status */
  status: InquiryStatus;
  /** Which "type" of inquiry this is for */
  vendorType: VendorTypeEnum;
}

/** A vendor lead (request to join zola by a vendor) that hasn't gone through camel casing */
export interface RawVendorLeadView {
  id: number;
  // eslint-disable-next-line camelcase
  business_name: string;
  // eslint-disable-next-line camelcase
  first_name: string;
  // eslint-disable-next-line camelcase
  last_name: string | null;
  // eslint-disable-next-line camelcase
  vendor_type: VendorTypeEnum;
  // eslint-disable-next-line camelcase
  instagram_handle: string | null;
  // eslint-disable-next-line camelcase
  reason_to_join: string | null;
  // eslint-disable-next-line camelcase
  weddings_covered: number | null;
  // eslint-disable-next-line camelcase
  certificate_of_insurance_available: boolean;
  // eslint-disable-next-line camelcase
  business_registered: boolean;
}

export interface OnboardingMarketView {
  onboardable: boolean;
}

/** A vendor market view that hasn't been camel cased */
export interface RawVendorMarketView extends OnboardingMarketView {
  id: number;
  label: string;
  key: string;
  // eslint-disable-next-line camelcase
  search_score: string | null;
  latitude: number | null;
  longitude: number | null;
  // eslint-disable-next-line camelcase
  radius_miles: number | null;
  searchable: boolean | null;
  // eslint-disable-next-line camelcase
  parent_id: number | null;
  // eslint-disable-next-line camelcase
  root_id: number;
  // eslint-disable-next-line camelcase
  market_level: string;
  // eslint-disable-next-line camelcase
  taxonomy_node_keys: SearchableVendorTaxonomyKey[];
  // eslint-disable-next-line camelcase
  updated_at: number | null;
}

export interface TaxonomyNodeToVendorMarketSearchView {
  id: number;
  key: VendorTaxonomyKey;
  /** market slugs or keys */
  searchableMarkets: string[];
}

export interface VendorMarketView {
  id: number;
  label: string;
  key: string;
  latitude: number | null;
  longitude: number | null;
  radiusMiles: number | null;
  onboardable: boolean;
  searchable: boolean | null;
  parentId: number | null;
  rootId: number;
  marketLevel: string;
  taxonomyNodeKeys: SearchableVendorTaxonomyKey[];
  updatedAt: number | null;
}

export interface MarketTaxonomyView {
  id: number;
  key: string;
  label: string;
  latitude: null | number;
  longitude: null | number;
  parentId: null | null;
  searchableVendors: TaxonomyNodeView[];
}

/** An address that has not gone through camel case transform */
interface RawAddressView {
  uuid: string;
  address1: string | null;
  address2: string | null;
  city: string;
  // eslint-disable-next-line camelcase
  state_province: string;
  // eslint-disable-next-line camelcase
  postal_code: string;
  // eslint-disable-next-line camelcase
  country_code: string | null;
  latitude: number;
  longitude: number;
  // eslint-disable-next-line camelcase
  google_maps_place_id: string | null;
  // eslint-disable-next-line camelcase
  possible_city_slug: string;
  // eslint-disable-next-line camelcase
  us_county_id: number;
}

export interface CreateAddressRequest {
  address1?: string | null;
  address2?: string | null;
  city: string;
  stateProvince: string;
  postalCode?: string | null;
  countryCode?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  googleMapsPlaceId?: string | null;
}

export interface StorefrontFileView {
  storefrontUuid: string;
  uuid: string;
  name: string;
  bytes: number;
  url: string;
}

export type EntityFacetView = RemoveNonNullables<BackendEntityFacetView, 'id' | 'facetId'>;

// TO DO update to use BackendOptionFacetView
export interface OptionFacetView {
  id: number;
  rootId: number;
  parentId: number | null;
  rootKey: string | null;
  parentKey: string | null;
  name: string;
  key: string;
  description: string | null;
  slug: string;
}

export interface SearchableOptionChildView {
  id: number;
  rootId: number;
  name: string;
  key: FacetChildKey;
  slug: string;
  description: string | null;
}

interface SearchableOptionFacetView {
  id: number;
  rootId: number;
  name: string;
  key: FacetParentKey;
  slug: string;
  description: string | null;
  facets: SearchableOptionChildView[];
}

export interface TaxonomyNodeType {
  id: number;
  key: VendorTaxonomyKey;
  label: string;
}

export type TaxonomyNodeView = BackendTaxonomyNodeView & {
  key: VendorTaxonomyKey;
};

export interface TaxonomyWithFacetsView extends Omit<TaxonomyNodeView, 'rootTaxonomyNodeId'> {
  facets: SearchableOptionFacetView[];
}

/**
 * This is a response giving option facets that isn't going through camel casing or mapping :()
 */
export interface RawOptionFacetView {
  id: number;
  // eslint-disable-next-line camelcase
  root_id: number;
  // eslint-disable-next-line camelcase
  root_key: string;
  name: string;
  key: FacetChildKey;
  description: string | null;
}
// Result of the OptionMapper
// @see server/mappers/optionMapper.js
export interface MappedOptionFacetView {
  id: number;
  key: string;
  name: string;
  description: string | null;
  parentId: number;
  parentKey: FacetParentKey | null;
}

/* Option Facets with local modifications, mostly for copy customizations */
export interface DecoratedOptionFacetView extends MappedOptionFacetView {
  dek?: ReactNode;
}

export type InquiryServicesFacet = {
  facetId: number;
  id: number;
  includeInInquiry: boolean;
  key: string;
  name: string;
  parentId: number;
  parentKey: FacetParentKey;
  description: string | null;
  rootId: number;
};

export interface MappedInquiryPreferencesView
  extends Omit<InquiryPreferencesView, 'weddingDate' | 'weddingYearMonthDate'> {
  weddingDate: string | null;
  weddingYearMonthDate: string | null;
  weddingStartPrice: number | null;
  weddingEndPrice: number | null;
  serviceEndPrice: number | null;
  serviceStartPrice: number | null;
  inquiryFormFacets: Record<string, string[]>;
}
export interface WeddingMarketContext {
  weddingLocation: AddressView | null;
  weddingMarket: VendorMarketView | null;
  suppliedVendorTypes: TaxonomyNodeView[];
  weddingCitySlug: string | null;
  weddingSeason: WeddingOptionFacetView | null;
  didCoupleOnboard: boolean;
  onboardedAt: number | null;
  venueBookedAt: number | null;
  highestPriorityVendorType: TaxonomyNodeView | null;
  accountVendorTypePriorities: TaxonomyNodeView[];
  marketServiced: boolean;
  venueBooked: boolean;
}

export interface AccountVendorPreferencesView {
  uuid: string;
  taxonomyNodeId: number;
  accountId: number;
  userObjectId: string;
  serviceEndPriceCents: number;
  flexibleOnPrice: boolean;
  facetsView: Array<OptionFacetView>;
}

export interface AccountWeddingPreferencesView {
  uuid: string | null;
  accountId: number | null;
  userObjectId: string | null;
  firstName: string | null;
  lastName: string | null;
  partnerFirstName: string | null;
  partnerLastName: string | null;
  eventDate: string | null;
  eventDateYearMonth: string | null;
  guestCount: number | null;
  weddingEndPriceCents: number | null;
  address: AddressView | null;
  vendorMarketId: number | null;
  flexibleOnLocation: boolean;
  flexibleOnDate: boolean;
  flexibleOnGuestCount: boolean;
  flexibleOnPrice: boolean;
  facetsView: Array<OptionFacetView> | null;
  loaded?: boolean;
}

export interface UpdateAccountVendorPreferenceRequest {
  taxonomyNodeId: number | null;
  userObjectId?: string;
  serviceEndPriceCents: number;
  optionFacetKeys?: Array<string>;
  flexibleOnPrice: boolean;
}

export interface SaveAccountWeddingPreferencesRequest {
  userObjectId: string | null;
  firstName: string | null;
  lastName: string | null;
  partnerFirstName: string | null;
  partnerLastName: string | null;
  eventDate: string | null;
  eventDateYearMonth: string | null;
  guestCount: number | null;
  weddingEndPriceCents: number | null;
  address?: CreateAddressRequest;
  vendorMarketId: number | null;
  weddingOptionFacetKeys: Array<string> | null;
  flexibleOnPrice: boolean | null;
  flexibleOnLocation: boolean;
  flexibleOnDate: boolean;
  flexibleOnGuestCount: boolean;
}

export interface UpdateWeddingLocationRequest {
  address: CreateAddressRequest;
  vendorMarketId?: number;
  flexibleOnLocation?: boolean;
  source?: BoardSourceEnum;
  origin?: PageOriginEnum;
}

/** A reference vendor view that hasn't gone through camel case transformations */
export interface RawReferenceVendorView {
  id: number;
  uuid: string | null;
  // eslint-disable-next-line camelcase
  taxonomy_node_id: number;
  // eslint-disable-next-line camelcase
  search_score: string;
  name: string;
  email: string;
  // eslint-disable-next-line camelcase
  website_url: string;
  phone: string | null;
  // eslint-disable-next-line camelcase
  wedding_ids: Array<number>;
  // eslint-disable-next-line camelcase
  match_percentage: number | null;
  address: RawAddressView;
}

// Result of GalleryPhotoMapper
// @see server/mappers/GalleryPhotoMapper.js
export interface MappedGalleryPhotoView {
  id: number;
  uuid: string;
  imageId: string;
  userId?: number;
  storefrontId?: number;
  height: number;
  width: number;
  photoCreditName?: string | null;
  photoCreditReferenceVendorId: number | null;
  photoCreditReferenceVendorSlug: string | null;
  imageUrl: string;
  displayOrder?: unknown;
  venueSpaceId?: number;
}

enum StorefrontLinkEnum {
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  MATTERPORT = 'MATTERPORT',
  PINTEREST = 'PINTEREST',
  SKETCHFAB = 'SKETCHFAB',
  TWITTER = 'TWITTER',
  VIMEO = 'VIMEO',
  WEB = 'WEB',
  YOUTUBE = 'YOUTUBE',
}

interface StorefrontLinkView {
  id: number;
  type: StorefrontLinkEnum;
  value: string;
}

export interface StorefrontCoverPhotoView {
  id: number;
  displayOrder: number;
  storefrontId: number;
  imageId: string;
  height: number;
  width: number;
}

enum StorefrontOnboardingVersionEnum {
  V1 = 'V1',
  V2 = 'V2',
  V3 = 'V3',
}

export interface StorefrontMarketsView {
  homeMarkets: Array<StorefrontMarketView>;
  travelMarkets: Array<StorefrontMarketView>;
}

export interface StorefrontMarketView {
  id: number;
  travelFeeRequired: boolean;
  market: VendorMarketView;
}

/** This is the raw, unmapped view of a storefront that is returned from the backend.  Generally, we want to avoid this and use a mapped endpoint instead. */
export interface StorefrontView {
  id: number;
  uuid: string;
  companyId: number;
  companyUuid: string;
  name: string;
  headline: string | null;
  description: string | null;
  claimedAt: null; // seems to always be null, if it wasn't null, it would be a number representing ms since epoc
  slug: string;
  closedAt: null; // seems to always be null, if it wasn't null, it would be a number representing ms since epoc
  hashtag: string | null;
  peakStartPriceCents: number | null;
  peakEndPriceCents: number | null;
  fullStartPriceCents: number | null;
  fullEndPriceCents: number | null;
  offPeakStartPriceCents: number | null;
  offPeakEndPriceCents: number | null;
  targetCoupleBudgetMaxCents: number | null;
  targetCoupleBudgetMinCents: number | null;
  targetPerPersonBudgetMaxCents: number | null;
  targetPerPersonBudgetMinCents: number | null;
  startingPriceCents: number | null;
  startingPricePerPersonCents: number | null;
  priceRangeVisible: boolean;
  insured: boolean;
  licensed: boolean;
  taxonomyNode: TaxonomyNodeView;
  storefrontLinks: Array<StorefrontLinkView>;
  storefrontCovers: Array<StorefrontCoverPhotoView>;
  publishedAt: number | null;
  updatedAt: number;
  verifiedAt: null; // seems to always be null, if it wasn't null, it would be a number representing ms since epoc
  status: 'ACTIVE'; // This is technically an enum, but only active storefronts are returned to marketplace
  publicationStatus: StorefrontPublicationStatusEnum;
  referenceVendorId: number | null;
  /** @deprecated - use Markets, this is only the home markets */
  vendorMarkets: Array<VendorMarketView>;
  markets: StorefrontMarketsView;
  availableThrough: number | null;
  onboardingVersion: StorefrontOnboardingVersionEnum;
  setsMeApart: string | null;
  avatarImageUuid: unknown;
  storefrontHeroImageUuid: unknown;
  optionFacetSlugs: string[];
  deletedAt: null;
  firstPublishedAt: null | number;
  storefrontResponsiveStatsView: StorefrontResponseStatsView;
  address: AddressView;
}

export enum VendorCardBadgeViewEnum {
  WEDDINGDATE = 'MATCHING_WEDDING_DATE',
  LOCATION = 'MATCHING_LOCATION',
  BUDGET = 'MATCHING_BUDGET',
  FLORISTETHICS = 'MATCHING_FLORIST_ETHICS',
  PHOTOGPERSONALITY = 'MATCHING_PHOTOG_PERSONALITY',
  PHOTOGSTYLE = 'MATCHING_PHOTOG_STYLE',
  VENUEATMOSPHERE = 'MATCHING_VENUE_ATMOSPHERE',
  VENUECAPACITY = 'MATCHING_VENUE_CAPACITY',
}

export type VendorCardView = BackendVendorCardView & {
  taxonomyNode: TaxonomyNodeView | null;
};

export interface StorefrontPreferredVendorView {
  id: number;
  storefrontId: number;
  vendorName: string;
  taxonomyNode: TaxonomyNodeView;
  city: string | null;
  stateProvince: string | null;
  email: string | null;
  inHouse: boolean;
  required: boolean;
  referenceVendorId: number | null;
}

interface PreferredVendorView {
  preferredVendorId: number;
  vendorCard: VendorCardView;
  address: AddressView | null;
  required: boolean;
  inHouse: boolean;
}
export interface PreferredVendorSummaryView {
  storefrontUuid: string;
  storefrontId: number;
  requiredVendors: PreferredVendorView[];
  optionalVendors: PreferredVendorView[];
}

export type AccountVendorView = RemoveNonNullables<
  BackendAccountVendorView,
  'id' | 'uuid' | 'accountId' | 'vendorType'
>;

export enum ConversationMessageEnum {
  COUPLE_OUTREACH = 'COUPLE_OUTREACH',
  VENDOR_OUTREACH = 'VENDOR_OUTREACH',
  VENDOR_CONNECT = 'VENDOR_CONNECT',
  VENDOR_DECLINE = 'VENDOR_DECLINE',
  VENDOR_RESPONSE = 'VENDOR_RESPONSE',
  COUPLE_RESPONSE = 'COUPLE_RESPONSE',
  SYSTEM_COUPLE_BOOK = 'SYSTEM_COUPLE_BOOK',
  SYSTEM_COUPLE_CANCEL = 'SYSTEM_COUPLE_CANCEL',
  SYSTEM_VENDOR_BOOK = 'SYSTEM_VENDOR_BOOK',
  SYSTEM_VENDOR_CANCEL = 'SYSTEM_VENDOR_CANCEL',
  SYSTEM_DISMISS = 'SYSTEM_DISMISS',
  SYSTEM_EXPIRATION = 'SYSTEM_EXPIRATION',
  SYSTEM_COUPLE_NUDGE = 'SYSTEM_COUPLE_NUDGE',
}

export interface RateLeadInquiryRequest {
  rating: number;
  ratingComment: string | null;
}

export interface VendorUserView {
  uuid: string;
  email: string;
  firstName: string | null;
  lastName: string | null;
  cognitoUuid: string;
  profileOnboardedAt: number | null;
  temporaryPassword: string | null;
  userType: 'ADMIN' | 'VENDOR' | 'COUPLE';
  phoneNumber: string | null;
  smsOptedInAt: Date | null;
  phoneUnsubscribed: boolean;
  loaded?: boolean;
}

/** result from fetching a user through the node layer and mapping it */
export interface MappedVendorUserView
  extends Omit<VendorUserView, 'cognitoUuid' | 'temporaryPassword'> {
  fullName: string;
  cognitoId: string;
}

/** Request to update a vendor's user */
export interface UpdateUserRequest {
  email: string;
  firstName: string | null;
  lastName: string | null;
  profileOnboardedAt: number | null; // really a date, this type might not be correct
  phoneNumber: string | null;
}

interface UserSettingsEnabledMap {
  notificationsEmailInquiryReceived: boolean;
  notificationsEmailProductFeedback: boolean;
  notificationsEmailProductTips: boolean;
  notificationsEmailProductUpdates: boolean;
  notificationsEmailReviewReceived: boolean;
  notificationsSmsInquiryReceived: boolean;
}
interface StorefrontUserSettings {
  userUuid: string;
  emailAlertOnMessageReceiptEnabled: boolean;
  emailAlertOnProductFeedbackEnabled: boolean;
  emailAlertOnProductTipsEnabled: boolean;
  emailAlertOnProductUpdatesEnabled: boolean;
}
/** A storefront users' settings for this storefront */
interface StorefrontUserSettingsView extends StorefrontUserSettings {
  storefrontUuid: string;
  userDisplayName: string;
  userEmail: string;

  settingToEnabledMap: UserSettingsEnabledMap;
}

/** userUuid => their settings */
export type StorefrontUserSettingsResponse = Record<string, StorefrontUserSettingsView>;

interface CreditOrderItem {
  creditPackage: {
    creditAmount: number;
    discountDurationDays: null | number;
    priceCents: number;
    discountRate: null | number;
    uuid: string;
    name: string;
  };
  discountExpirationDate: null | number;
  quantity: number;
  priceCents: number;
}

/**
 * what's returned from the credit orders endpoint (for the vendor
 * purchase history page)
 *
 */
export interface CreditOrderView {
  objectId: string;
  orderNumber: string;
  placedAt: Date;
  items: CreditOrderItem[];
  totals: {
    subtotalCents: number;
    taxCents: number;
    totalCents: number;
  };
  payment: {
    paymentSource: Payment.PaymentSourceEnum;
    cardBrand: string;
    cardLastDigits: string;
  };
  user: VendorUserView;
}

export interface CreditOrderStatsView {
  currentYearSpendCents: number;
}

interface MessageFileAttachmentRequest {
  storefrontFileUuid: string;
}
interface MessageAttachmentRequest {
  type: MessageAttachmentTypeEnum;
  displayText?: string;
  url: string;
}
export interface ConnectInquiryRequest {
  inquiryUuid: string;
  storefrontResponse?: string;
  attachments?: Array<MessageAttachmentRequest>;
  fileAttachments?: Array<MessageFileAttachmentRequest>;
  includePayment?: boolean;
}

export enum CancelInquiryReasons {
  NEEDS_CHANGED = 'NEEDS_CHANGED',
  BOOKED_ANOTHER_VENDOR = 'BOOKED_ANOTHER_VENDOR',
  PRICING_DID_NOT_MATCH_BUDGET = 'PRICING_DID_NOT_MATCH_BUDGET',
  MISSING_SERVICES = 'MISSING_SERVICES',
  JUST_BROWSING = 'JUST_BROWSING',

  // Use UNRESPONSIVE for both couples and vendors for consistency, and the actor tells us who done it.
  UNRESPONSIVE = 'UNRESPONSIVE',

  OTHER = 'OTHER',

  REQUESTED_DATE = 'REQUESTED_DATE',
  COUPLE_LOCATION = 'COUPLE_LOCATION',
}

export enum InquiryDeclineReasonsEnum {
  REQUESTED_DATE = 'REQUESTED_DATE',
  WEDDING_BUDGET = 'WEDDING_BUDGET',
  VENDOR_BUDGET = 'VENDOR_BUDGET',
  COUPLE_LOCATION = 'COUPLE_LOCATION',
  COUPLE_STYLE = 'COUPLE_STYLE',
  NEED_MORE_INFO = 'NEED_MORE_INFO',
  GUEST_COUNT = 'GUEST_COUNT',
  CONNECTED_OFF_PLATFORM = 'CONNECTED_OFF_PLATFORM',
  NOT_BOOK_PREFERRED_VENDOR = 'NOT_BOOK_PREFERRED_VENDOR',
  SAVING_CREDITS = 'SAVING_CREDITS',
  NO_CREDITS = 'NO_CREDITS',
  OTHER = 'OTHER',
}

export interface DeclineInquiryRequest {
  inquiryUuid: string;
  inquiryDeclineReasons?: Array<InquiryDeclineReasonsEnum>;
  declineReasonText?: { [key in InquiryDeclineReasonsEnum]: string };
  attachments?: Array<MessageAttachmentRequest>;
  fileAttachments?: Array<MessageFileAttachmentRequest>;
  storefrontResponse?: string;
  storefrontReferralIds?: Array<number>;
  unavailableDate?: string;
}

export interface StorefrontReferralView {
  id: number;
  storefrontId: number;
  vendorCardView: VendorCardView;
}

/**
 * @see http://34.192.78.81:9170/swagger#/Storefront/getStorefrontPrivacySettings
 */
export interface StorefrontPrivacySettingView {
  storefrontId: number;
  allowPhotoTag: boolean;
  allowPreferredList: boolean;
  showInInquiredVendorFeed?: boolean;
  showInBookedVendorFeed?: boolean;
}

export interface QuizletAnswerView {
  key: string;
  liked: boolean;
  answeredAt: number;
}

export interface QuizletAnswerRequest {
  key: string;
  liked: boolean;
  source: BoardSourceEnum;
  origin: PageOriginEnum;
}

export interface WeddingOptionFacetView extends OptionFacetView {
  // This is nullable and always has been, but if I make it nullable lots of code becomes unhappy.
  colorSwatch: string;
  childFacets: Array<WeddingOptionFacetView>;
  emoji: string | null;
}

export interface WeddingDetailsFacetView {
  id: number;
  rootId: number;
  rootKey: string;
  name: string;
  key: string;
  description: string;
  emoji: string;
}

export interface ManageWeddingDetailsView {
  uuid: string;
  slug: string;
  name: string;
  partner1Name: string;
  partner2Name: string;
  coupleEmail: string;
  address: AddressView;
  eventDate: string;
  requestReviewAfterPublication: boolean;
  privateResidence: boolean;
  facets: WeddingDetailsFacetView[] | null;
}

export interface DeletedStorefrontWeddingView {
  id: number;
  uuid: string;
  title: string;
  address: AddressView;
  slug: string;
}

export interface StorefrontWeddingGalleryView {
  id: number;
  weddingId: number;
  weddingUuid: string;
  imageId: string;
  height: number;
  width: number;
  slug: string;
}

interface SlimStorefrontView {
  uuid: string;
  name: string;
}

export interface SlimInquiryView {
  uuid: string;
  userObjectId: string;
  primaryFirstName: string;
  primaryLastName: string;
  partnerFirstName: string;
  partnerLastName: string;
  status: InquiryStatus;
}

export enum TransactionTypesEnum {
  CREDIT_PURCHASE = 'CREDIT_PURCHASE',
  CREDIT_PURCHASE_CANCELLATION = 'CREDIT_PURCHASE_CANCELLATION',
  ENDOWMENT = 'ENDOWMENT',
  EXPIRATION = 'EXPIRATION',
  LEAD_PURCHASE = 'LEAD_PURCHASE',
  LEAD_PURCHASE_REFUND = 'LEAD_PURCHASE_REFUND',
  INBOUND_TRANSFER = 'INBOUND_TRANSFER',
  OUTBOUND_TRANSFER = 'OUTBOUND_TRANSFER',
  OTHER = 'OTHER',
}

/**
 * A credit transaction, used on a vendor's purchase history page (balance history)
 *
 */
export interface Transaction {
  date: number;
  type: TransactionTypesEnum;
  amount: number;
  note: string;
  balance: number;
  expirationDate: number | null;
  storefront: SlimStorefrontView | null;
}

interface CreditTransactionView {
  uuid: string;
  amount: number;
}

/**
 * A lead purchase object, used on a vendor's purchase history page (lead receipts)
 *
 */
export interface InquiryPaymentView {
  uuid: string;
  createdAt: Date;
  refundedAt: Date;
  creditAmount: number;
  baseCreditPrice: number;
  storefront: SlimStorefrontView;
  inquiry: SlimInquiryView;
  user: VendorUserView;
  paymentTransaction: CreditTransactionView;
  refundTransaction: CreditTransactionView;
}

interface CreditCardAddress {
  streetLine1?: string;
  streetLine2?: string;
  city?: string;
  stateProvinceRegion?: string;
  postalCode?: string;
  countryCode?: BillingAddress.CountryCodeEnum;
  phoneNumber?: string;
}

export type CreditCardTypeEnum = BackendCreditCardView.CreditCardTypeEnum;
type PaymentProcessorEnum = BackendCreditCardView.PaymentProcessorEnum;
/**
 * A saved credit card object
 *
 * This isn't really possible to get from the svc-marketplace types (at least
 * easily) since its actually defined in a different service (Order Service)
 * and we'ld probably need to redfine a CreditCardView just for svc-marketplace
 * with mappers and all that
 *
 * @see BackendCreditCardView
 * @see com.zola.orderservice.api.CreditCardView
 */
export interface CreditCardView {
  uuId: string | null;
  id: number;
  uuid: string | null;
  userObjectId: string | null;
  paymentProcessor: PaymentProcessorEnum | null;
  cardholderName: string;
  cardReference: string;
  creditCardType: CreditCardTypeEnum;
  lastFourDigits: string;
  defaultCard: boolean;
  expirationMonth: number;
  expirationYear: number;
  createdAt: number;
  updatedAt: number;
  billingAddress: CreditCardAddress;
}
export interface DebitBankAccountView
  extends Omit<BackendDebitBankAccountView, 'debitBankAccountReference'> {
  debitBankAccountReference: string | undefined;
}
export enum BoardSourceEnum {
  ALL = 'ALL',
  WEB = 'WEB',
  MOBILE_WEB = 'MOBILE_WEB',
  IPAD_APP = 'IPAD_APP',
  IPHONE_APP = 'IPHONE_APP',
  IPHONE_WEDDING_APP = 'IPHONE_WEDDING_APP',
  ANDROID_WEDDING_APP = 'ANDROID_WEDDING_APP',
  BOOKMARKLET = 'BOOKMARKLET',
}
export interface BoardStorefrontView {
  uuid: string;
  boardUuid: string;
  storefrontUuid: string;
  vendorCard: VendorCardView;
  source: BoardSourceEnum;
  createdAt: number;
}

export interface BoardWeddingPhotoView {
  uuid: string;
  boardUuid: string;
  weddingUuid: string;
  weddingSlug: string;
  weddingPhotoId: number;
  weddingPhotoSlug: string;
  imageId: string;
  width: number;
  height: number;
  source: BoardSourceEnum;
  vendorCardViewList: VendorCardView[] | null; // NOTE: vendorCardViewList will always be null except for photos with vendors returned from POST requests to http://34.192.78.81:9170/swagger#/Board/addWeddingPhotoId
  weddingView: WeddingView;
}

export type VendorTypeCountKey =
  | 'weddingVenues'
  | 'weddingPhotographers'
  | 'weddingVideographers'
  | 'weddingFlorists'
  | 'weddingCatering'
  | 'weddingCakesDesserts'
  | 'weddingBandsDjs'
  | 'weddingHairMakeup';

export interface MappedBoardView {
  uuid: string;
  favoritePhotos: BoardWeddingPhotoView[];
  favoriteStorefronts: BoardStorefrontView[];
  vendorTypeCounts: PartialRecord<VendorTypeCountKey, number>;
}

export interface SocialLinkView {
  facebook: string | null;
  twitter: string | null;
  instagram: string | null;
  pinterest: string | null;
  web: string | null;
}

// This was copied straight from the API definition.  Some of these fields may be not be nullable.
export type PromotionView = Nullable<{
  key: string;
  name: string;
  description: string;
  startDate: number;
  endDate: number;
}>;

// COLLECTIONS START
interface Image {
  backgroundHexColor: string;
  remoteUrl: string;
}

export interface Bucket {
  value: string;
  count: number;
  name: string;
  children: Bucket[];
  image: Image;
}

interface Option {
  value: string;
  itemCount: number;
  selected: boolean;
}

export interface ParamSelect {
  key: string;
  options: Option[];
}

export interface WeddingPhotoDetailView {
  imageId: string;
  height: number;
  width: number;
  facets: WeddingOptionFacetView[];
  recommendedFacets: WeddingOptionFacetView[];
  suggestedFacets: WeddingOptionFacetView[];
  weddingPhotoViews: WeddingPhotoTileView[];
  vendorCardViews: VendorCardView[];
  wedding: WeddingView;
  similarPhotos: WeddingPhotoTileView[];
  suggestedVendors: VendorCardView[];
  weddingFacets: WeddingOptionFacetView[];
  photographerName: string | null;
}

export interface WeddingAlbumDetailView {
  wedding: WeddingView;
  weddingPhotoViews: WeddingPhotoTileView[];
  vendorCardViews: VendorCardView[];
  facets: OptionFacetView[];
  suggestedFacets: OptionFacetView[];
  weddingPaletteFacets: WeddingOptionFacetView[];
  suggestedVendors: VendorCardView[];
}

export interface WeddingPhotoTileView {
  height: number;
  width: number;
  imageId: string;
  slug: string;
  facets: WeddingOptionFacetView[];
  bioPhotoImageIds: string[];
  photographerName: string | null;
}

export interface WeddingLandingPageView {
  photoGrid: WeddingPhotoTileView[];
  suggestedPhotoOptionFacets: WeddingOptionFacetView[];
  requestId: string;
}

export interface WeddingPersonalLandingPageView {
  landscape: InspirationSearchResponse;
  portrait: InspirationSearchResponse;
}

/**
 * @see http://34.192.78.81:9170/swagger#/Board/getPredefinedCollectionByAccountId
 */
export interface BoardCollectionView {
  weddingPhotos: BoardWeddingPhotoView[];
  savedStorefronts: BoardStorefrontView[];
  suggestedStorefronts: VendorCardView[];
  popularFacets: WeddingOptionFacetView[];
  photoCount: number;
  vendorCount: number;
}

export interface VenueSpaceView {
  /** @deprecated - use uuid */
  id: number;
  uuid: string;
  venueId: number;
  name: string;
  minSeatedCapacity: number | null;
  maxSeatedCapacity: number | null;
  minStandingCapacity: number | null;
  maxStandingCapacity: number | null;
  squareFeet: number | null;
  cost: string | null;
  pullQuote: string | null;
  description: string | null;
  included: string | null;
  guestsFeel: string | null;
  displayOrder: number | null;
  includedInVenueCost: boolean;
  floorplanImageUrl: string | null;
  floorplanUuid: string | null;
  venueSpaceGalleryViews: MappedGalleryPhotoView[];
  entityFacetViews: MappedOptionFacetView[] | null;
}

export interface BoardDetailView {
  uuid: string | null;
  savedStorefronts: BoardStorefrontView[];
  suggestedStorefronts: VendorCardView[];
  weddingPhotos: BoardWeddingPhotoView[];
  suggestedPhotos: WeddingPhotoTileView[];
  suggestedFacets: WeddingOptionFacetView[];
  popularFacets: WeddingOptionFacetView[];
  vendorTypeCounts: Record<string, number>;
}

export interface BoardCollectionPreviewView {
  imageId: string | null;
  name: string | null;
  key: string | null;
  vendorType: VendorTypeEnum | null;
  numPhotos: number | null;
  numVendors: number;
}

/**
 * ReviewView['reviewImageIds'] mapped with additional ReviewView
 * fields to ease rendering of each review photo with review data
 * @see server/mappers/reviewMapper.js
 */
export interface ReviewPhotoView {
  overallRating: number;
  reviewerName: string;
  reviewUuid: string;
  sentToBvAt: number | null;
  title: string;
  uuid: string;
}

/** Published reviews, like a ReviewView, but with these fields removed:
 * - accountId
 * - bvReviewId
 * - email
 * - nonce
 * - reviewerType
 * - reviewRequestUuid
 * - reviewStatus
 * - userObjectId
 * @see server/mappers/reviewMapper.js
 */
export interface MappedReviewView {
  /**
   * @deprecated Use the `responses` prop instead
   */
  responseText?: string;
  /**
   * @deprecated Use the `responses` prop instead
   */
  respondedAt: number | null;
  storefrontUuid: string;
  reviewUuid: string;
  reviewStatus: 'APPROVED';
  overallRating: number;
  professionalismRating: number;
  communicationsRating: number;
  workingWithRating: number;
  qualityRating: number;
  reviewerName: string;
  reviewerBusinessName: string;
  title: string;
  reviewText: string;
  sentToBvAt: number | null;
  incentivized: boolean;
  reviewImageIds: string[] | null;
  responses: ReviewResponseView[];
}

/**
 * A persisted Caterer Story.  Persisted stories always have an id, uuid, name,
 * catererUuid.  Additionally, this is still using the vendorCardView defined
 * here instead of the version defined in the service.
 */
export interface CatererStoryView
  extends RemoveNonNullables<
    Omit<BackendCatererStoryView, 'vendorCard'>,
    'id' | 'catererUuid' | 'uuid' | 'name'
  > {
  // This is using the response types definition still, not the version from the service
  vendorCard: VendorCardView;
}

export interface PaginatedSearchResponseViewListVendorCardView {
  offset: number;
  limit: number;
  hasMoreResults: number;
  entities: VendorCardView[];
  totalHits: number;
}

export type LocationOrAllMarketsType =
  | SearchLocationView
  | {
      type: 'ALL_MARKETS' | 'NO_MARKETS';
      label: string;
      slug: string;
      stateSlug?: undefined;
      marketSlug?: undefined;
      usCityId?: undefined;
      storefrontSearchPayload?: undefined;
      geoCoordinates?: undefined;
    };

export enum PageOriginEnum {
  REAL_WEDDINGS_LANDING_PAGE = 'REAL_WEDDINGS_LANDING_PAGE',
  REAL_WEDDINGS_SEARCH_RESULTS_PAGE = 'REAL_WEDDINGS_SEARCH_RESULTS_PAGE',
  REAL_WEDDINGS_PHOTO_DETAIL_PAGE = 'REAL_WEDDINGS_PHOTO_DETAIL_PAGE',
  REAL_WEDDINGS_ALBUM_DETAIL_PAGE = 'REAL_WEDDINGS_ALBUM_DETAIL_PAGE',
  REAL_WEDDINGS_FAVORITES = 'REAL_WEDDINGS_FAVORITES',
}

export interface StartImageUploadJobRequest {
  uploadcareUuids: string[];
}

/* returned from the UC file widget */
export interface UploadCareFile {
  uploadcareUuid: string;
  ucareImageUuid: string;
  fileName: string;
  width: number;
  height: number;
  size: number;
  tags: unknown;
  lastModifiedDate: number;
  mimeType: string;
}

// should be WUserSettingView from '@zola/svc-web-api-ts-client';
// but the type for createdAt & updatedAt there do not represent
// what we get back from the endpoint (in WUserSettingView the type
// is Date | undefined)
export interface UserSettingView {
  flag: boolean;
  createdAt: string | null;
  updatedAt: string | null;
}

export interface MappedVenueMenuView {
  id: number;
  name: string;
  startPriceCents: number;
  endPriceCents: number | null;
  description: string | null;
  link: string | null;
  otherOptions: string | null;
  options: MappedOptionFacetView[];
  startPrice: number;
  endPrice: number | null;
}

export interface AccountVendorResultView {
  accountVendor: BackendAccountVendorView;
  budgetSyncResolution: BudgetVendorSyncResolutionView;
}

export interface BudgetResolutionFormValues {
  accountVendorUuid: string;
  budgetItemUuid: string | null;
  costCents: number;
}

export type VendorLandingPageView = {
  savedSearchCount?: number;
  alsoBookedCrossCategory: Array<StorefrontCardView>;
  mostRecentInquiry?: InquiryView | null;
  mostRecentBooked?: AccountVendorView | null;
  alsoInquiredSameCategory?: Array<StorefrontCardView>;
  inquiryDrafts?: Array<InquiryDraftCardView>;
  nonInquiredFavorites?: Array<StorefrontCardView>;
  showTopPicks?: boolean;
  topPicks?: Array<StorefrontCardView>;
};

export type SrpDataView = Omit<BackendSrpDataView, 'searchableFacets'> & {
  searchableFacets: TaxonomyWithFacetsView[];
};

export type RelatedLinkGroupView = {
  heading: string;
  relatedLinks: RelatedLinkView[];
};
