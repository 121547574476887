import {
  BANDS_DJS_TAXONOMY_KEY,
  BAR_SERVICES_TAXONOMY_KEY,
  CAKES_DESSERTS_TAXONOMY_KEY,
  CATERING_TAXONOMY_KEY,
  EXTRAS_TAXONOMY_KEY,
  FLORISTS_TAXONOMY_KEY,
  HAIR_MAKEUP_TAXONOMY_KEY,
  OFFICIANTS_TAXONOMY_KEY,
  PHOTOGRAPHERS_TAXONOMY_KEY,
  PLANNERS_TAXONOMY_KEY,
  SearchableVendorTaxonomyKey,
  VENUES_TAXONOMY_KEY,
  VIDEOGRAPHERS_TAXONOMY_KEY,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _uniq from 'lodash/uniq';
import { createSelector } from 'reselect';

import { getServicesOfferedMetaForVendorTaxonomyKey } from '~/meta/services';
import { LEAD_PREFERENCE_FACETS } from '~/pages/vendors/LeadPreferences/meta';
import type { RootState } from '~/reducers';
import { getVideoGallery } from '~/reducers/entities/videoGalleryReducer';
import {
  AnyVendorDetails,
  BakerDetails,
  BeauticianDetails,
  CatererDetails,
  ExtrasDetails,
  FloristDetails,
  MusicianDetails,
  OfficiantDetails,
  PhotographerDetails,
  StorefrontPublicationStatusEnum,
  VenueDetails,
  VideographerDetails,
  WeddingPlannerDetails,
} from '~/types/responseTypes';
import { Social, VendorStorefrontDetails } from '~/types/storefrontDetails';
import { normalizeReviewEmailAddress } from '~/util/emailNormalization';
import { getOptionsForParentKeys } from '~/util/facetUtils';
import Logger from '~/util/logger';
import { centsToDollars } from '~/util/priceConversion';

type ValueOf<T> = T[keyof T];
type KeyOfStorefrontDetails = keyof VendorStorefrontDetails;
type ValueOfStorefrontDetails = ValueOf<VendorStorefrontDetails>;

export type AllVendorDetailsExceptVenues =
  | BakerDetails
  | BeauticianDetails
  | CatererDetails
  | ExtrasDetails
  | FloristDetails
  | MusicianDetails
  | OfficiantDetails
  | PhotographerDetails
  | VideographerDetails
  | WeddingPlannerDetails;

export const getCurrentStorefront = (state: RootState): VendorStorefrontDetails | null =>
  _get(state, 'vendorStorefront.storefrontDetails', null);

// This returns a slice of the state from the storefront details down, I don't know how to type that yet
const getState = (
  state: RootState,
  key: KeyOfStorefrontDetails,
  defaultValue: ValueOfStorefrontDetails = null
): ValueOfStorefrontDetails => _get(getCurrentStorefront(state), key, defaultValue);

/** Gets the low end of the off peak price range, in dollars */
export const getOffPeakStartPrice = (state: RootState): number | null =>
  getState(state, 'offPeakStartPrice') as number | null;

/** Gets the high end of the off peak price range, in dollars */
export const getOffPeakEndPrice = (state: RootState): number | null =>
  getState(state, 'offPeakEndPrice') as number | null;

/** Gets the low end of the peak price range, in dollars  */
export const getPeakStartPrice = (state: RootState): number | null =>
  getState(state, 'peakStartPrice') as number | null;

/** Gets the high end of the peak price range, in dollars  */
export const getPeakEndPrice = (state: RootState): number | null =>
  getState(state, 'peakEndPrice') as number | null;

export const getFullStartPrice = (state: RootState): number | null => {
  const { fullStartPriceCents } = state.vendorStorefront?.storefrontDetails || {};
  if (!fullStartPriceCents) return null;
  return centsToDollars(fullStartPriceCents);
};

export const getFullEndPrice = (state: RootState): number | null => {
  const { fullEndPriceCents } = state.vendorStorefront?.storefrontDetails || {};
  if (!fullEndPriceCents) return null;
  return centsToDollars(fullEndPriceCents);
};

/** planners only */
export const getFullBudgetStartPrice = (
  state: RootState,
  storefrontId: number | undefined
): number | null => {
  if (storefrontId) {
    const planner = state.entities.planners.byId[storefrontId];
    const { targetCoupleFullBudgetMinCents } = planner;
    return centsToDollars(targetCoupleFullBudgetMinCents);
  }
  return null;
};
export const getFullBudgetEndPrice = (
  state: RootState,
  storefrontId: number | undefined
): number | null => {
  if (storefrontId) {
    const planner = state.entities.planners.byId[storefrontId];
    const { targetCoupleFullBudgetMaxCents } = planner;
    return centsToDollars(targetCoupleFullBudgetMaxCents);
  }
  return null;
};

export const getVendorTaxonomyKey = (state: RootState): SearchableVendorTaxonomyKey =>
  (getState(state, 'taxonomyKey', '') as string).toLowerCase() as SearchableVendorTaxonomyKey;

export const getVendorTaxonomyNodeId = (state: RootState): number =>
  getState(state, 'taxonomyNodeId') as number;

export const getIsPublished = (state: RootState): boolean =>
  getState(state, 'publishedAt') !== null;

export const getStorefrontSlug = (state: RootState): string => getState(state, 'slug') as string;

export const getStorefrontUuid = (state: RootState): string => getState(state, 'uuid') as string;

export const getCurrentVendorIsVenue = createSelector(
  getVendorTaxonomyKey,
  (taxonomyKey) => taxonomyKey === VENUES_TAXONOMY_KEY
);
/**
 * Returns the status of a publication request
 * @returns StorefrontPublicationStatusEnum
 */
const getPublicationStatus = (state: RootState): StorefrontPublicationStatusEnum =>
  getState(state, 'publicationStatus') as StorefrontPublicationStatusEnum;

export const getIsInReview = createSelector(getPublicationStatus, (publicationStatus) => {
  return (
    publicationStatus === StorefrontPublicationStatusEnum.SUBMITTED ||
    publicationStatus === StorefrontPublicationStatusEnum.INREVIEW
  );
});

export const isStorefrontNotFound = (state: RootState): boolean => {
  return _get(state, 'vendorStorefront.noMatch') || false;
};

// Vendor only
export const getCurrentVenueDetails = (state: RootState): VenueDetails | undefined => {
  const details = state.vendorStorefront.storefrontDetails;
  if (details?.taxonomyKey === VENUES_TAXONOMY_KEY) {
    return details.venueDetails;
  }
  return undefined;
};

// Photographers
// TODO: Move to new file, this is shared between couples and vendors
export const getPhotographerDetails = (
  state: RootState,
  storefrontId: number | undefined
): PhotographerDetails | undefined => {
  if (storefrontId) {
    const photographerId = state.photographer.storefrontToId[storefrontId];
    return state.photographer.byId[photographerId];
  }
  return undefined;
};

// Vendor only
export const getCurrentPhotographerDetails = (
  state: RootState
): PhotographerDetails | undefined => {
  const storefront = getCurrentStorefront(state);
  if (storefront) {
    return getPhotographerDetails(state, storefront.id);
  }
  return undefined;
};

type ElopmentSelectorType = (
  state: RootState
) => { elopementStartPriceCents: number | null; elopementEndPriceCents: number | null } | undefined;

const getElopmentStartPriceCents = (
  state: RootState,
  vendorSelector: ElopmentSelectorType
): number | null => {
  const vendorDetails = vendorSelector(state);
  if (vendorDetails) {
    return vendorDetails.elopementStartPriceCents;
  }
  return null;
};
const getElopmentStartPrice = (
  state: RootState,
  vendorSelector: ElopmentSelectorType
): number | null => {
  return centsToDollars(getElopmentStartPriceCents(state, vendorSelector));
};

const getElopmentEndPriceCents = (
  state: RootState,
  vendorSelector: ElopmentSelectorType
): number | null => {
  const vendorDetails = vendorSelector(state);
  if (vendorDetails) {
    return vendorDetails.elopementEndPriceCents;
  }
  return null;
};
const getElopmentEndPrice = (
  state: RootState,
  vendorSelector: ElopmentSelectorType
): number | null => {
  return centsToDollars(getElopmentEndPriceCents(state, vendorSelector));
};

/**
 * Gets the low end of the photographer elopment price range, in dollars.
 *
 * @param state RootState
 */
export const getPhotographerElopmentStartPrice = (state: RootState): number | null =>
  getElopmentStartPrice(state, getCurrentPhotographerDetails);
export const getPhotographerElopmentStartPriceCents = (state: RootState): number | null =>
  getElopmentStartPriceCents(state, getCurrentPhotographerDetails);

/**
 * Gets the high end of the photographer elopment price range, in dollars.
 *
 * @param state RootState
 */
export const getPhotographerElopmentEndPrice = (state: RootState): number | null =>
  getElopmentEndPrice(state, getCurrentPhotographerDetails);
export const getPhotographerElopmentEndPriceCents = (state: RootState): number | null =>
  getElopmentEndPriceCents(state, getCurrentPhotographerDetails);

// Caterers
// TODO: Move to new file, this is shared between couples and vendors
export const getCatererDetails = (
  state: RootState,
  storefrontId: number
): CatererDetails | undefined => {
  const catererId = state.entities.caterers.storefrontToId[storefrontId];
  return state.entities.caterers.byId[catererId];
};

// Vendor only
export const getCurrentCatererDetails = (state: RootState): CatererDetails | undefined => {
  const storefront = getCurrentStorefront(state);
  if (storefront) {
    return getCatererDetails(state, storefront.id);
  }
  return undefined;
};

export const getCatererStartPricePerPersonCents = (state: RootState): number | null => {
  const catererDetails = getCurrentCatererDetails(state);
  if (catererDetails) {
    return catererDetails.startPriceCentsPerPerson;
  }
  return null;
};
export const getCatererStartPricePerPerson = (state: RootState): number | null => {
  return centsToDollars(getCatererStartPricePerPersonCents(state));
};

export const getCatererMinimumHeadCount = (state: RootState): number | null => {
  const catererDetails = getCurrentCatererDetails(state);
  if (catererDetails) {
    return catererDetails.minimumHeadCount;
  }
  return null;
};

export const getCatererAlcoholPerPersonStartPriceCents = (state: RootState): number | null => {
  const catererDetails = getCurrentCatererDetails(state);
  if (catererDetails) {
    return catererDetails.alcoholPerPersonStartPriceCents;
  }
  return null;
};

export const getCatererAlcoholPerPersonStartPrice = (state: RootState): number | null => {
  return centsToDollars(getCatererAlcoholPerPersonStartPriceCents(state));
};

export const getCatererMinimumSpendCents = (state: RootState): number | null => {
  const catererDetails = getCurrentCatererDetails(state);
  if (catererDetails) {
    return catererDetails.startPriceCents;
  }
  return null;
};
export const getCatererMinimumSpend = (state: RootState): number | null => {
  return centsToDollars(getCatererMinimumSpendCents(state));
};

// Bakers
// TODO: Move to new file, this is shared between couples and vendors
export const getBakerDetails = (
  state: RootState,
  storefrontId: number
): BakerDetails | undefined => {
  const bakerId = state.entities.bakers.storefrontToId[storefrontId];
  return state.entities.bakers.byId[bakerId];
};

// Vendor only
export const getCurrentBakerDetails = (state: RootState): BakerDetails | undefined => {
  const storefront = getCurrentStorefront(state);
  if (storefront) {
    return getBakerDetails(state, storefront.id);
  }
  return undefined;
};

export const getBakerMinimumServingCount = (state: RootState): number | null => {
  const bakerDetails = getCurrentBakerDetails(state);
  if (bakerDetails) {
    return bakerDetails.minimumHeadCount;
  }
  return null;
};

export const getBakerStartPricePerPersonCents = (state: RootState): number | null => {
  const bakerDetails = getCurrentBakerDetails(state);
  if (bakerDetails) {
    return bakerDetails.startPriceCentsPerPerson;
  }
  return null;
};

export const getBakerStartPricePerPerson = (state: RootState): number | null => {
  return centsToDollars(getBakerStartPricePerPersonCents(state)); // This seems to be a copy of the startPriceCentsPerPerson
};

export const getBakerStartPriceCents = (state: RootState): number | null => {
  const bakerDetails = getCurrentBakerDetails(state);
  if (bakerDetails) {
    return bakerDetails.minimumSpendCents;
  }
  return null;
};
/**
 * Get the baker minimum spend for the current vendor storefront (not couples) in dollars.
 *
 * @param state
 */
export const getBakerStartPrice = (state: RootState): number | null => {
  return centsToDollars(getBakerStartPriceCents(state));
};

// TODO: Move to new file, this is shared between couples and vendors
export const getMusicianDetails = (
  state: RootState,
  storefrontId: number
): MusicianDetails | undefined => {
  const musicianId = state.entities.musicians.storefrontToId[storefrontId];
  return state.entities.musicians.byId[musicianId];
};

// Vendor only
export const getCurrentMusicianDetails = (state: RootState): MusicianDetails | undefined => {
  const storefront = getCurrentStorefront(state);
  if (storefront) {
    return getMusicianDetails(state, storefront.id);
  }
  return undefined;
};

export const getMusicianCeremonyStartPriceCents = (state: RootState): number | null => {
  const musicianDetails = getCurrentMusicianDetails(state);
  if (musicianDetails) {
    return musicianDetails.ceremonyStartPriceCents;
  }
  return null;
};
/**
 * Gets the minimum price for a cermoney for a musician, in dollars.
 *
 * @param state RootState
 */
export const getMusicianCeremonyStartPrice = (state: RootState): number | null => {
  return centsToDollars(getMusicianCeremonyStartPriceCents(state));
};

export const getMusicianReceptionStartPriceCents = (state: RootState): number | null => {
  const musicianDetails = getCurrentMusicianDetails(state);
  if (musicianDetails) {
    return musicianDetails.receptionStartPriceCents;
  }
  return null;
};
/**
 * Gets the minimum price for a reception for a musician, in dollars.
 *
 * @param state RootState
 */
export const getMusicianReceptionStartPrice = (state: RootState): number | null => {
  return centsToDollars(getMusicianReceptionStartPriceCents(state));
};

// Planners
// TODO: Move to new file, this is shared between couples and vendors
export const getPlannerDetails = (
  state: RootState,
  storefrontId: number
): WeddingPlannerDetails | undefined => {
  const plannerId = state.entities.planners.storefrontToId[storefrontId];
  return state.entities.planners.byId[plannerId];
};

// Vendor only
export const getCurrentPlannerDetails = (state: RootState): WeddingPlannerDetails | undefined => {
  const storefront = getCurrentStorefront(state);
  if (storefront) {
    return getPlannerDetails(state, storefront.id);
  }
  return undefined;
};

// Officiants
// TODO: Move to new file, this is shared between couples and vendors
export const getOfficiantDetails = (
  state: RootState,
  storefrontId: number
): OfficiantDetails | undefined => {
  const officiantId = state.entities.officiants.storefrontToId[storefrontId];
  return state.entities.officiants.byId[officiantId];
};

// Vendor only
export const getCurrentOfficiantDetails = (state: RootState): OfficiantDetails | undefined => {
  const storefront = getCurrentStorefront(state);
  if (storefront) {
    return getOfficiantDetails(state, storefront.id);
  }
  return undefined;
};

// Extras
// TODO: Move to new file, this is shared between couples and vendors
export const getExtrasDetails = (
  state: RootState,
  storefrontId: number
): ExtrasDetails | undefined => {
  const extrasId = state.entities.extras.storefrontToId[storefrontId];
  return state.entities.extras.byId[extrasId];
};

// Vendor only
export const getCurrentExtrasDetails = (state: RootState): ExtrasDetails | undefined => {
  const storefront = getCurrentStorefront(state);
  if (storefront) {
    return getExtrasDetails(state, storefront.id);
  }
  return undefined;
};

// Videographers
// TODO: Move to new file, this is shared between couples and vendors
export const getVideographerDetails = (
  state: RootState,
  storefrontId: number
): VideographerDetails | undefined => {
  const videographerId = state.entities.videographers.storefrontToId[storefrontId];
  return state.entities.videographers.byId[videographerId];
};

// Vendor only
export const getCurrentVideographerDetails = (
  state: RootState
): VideographerDetails | undefined => {
  const storefront = getCurrentStorefront(state);
  if (storefront) {
    return getVideographerDetails(state, storefront.id);
  }
  return undefined;
};

/**
 * Gets the low end of the photographer elopment price range, in dollars.
 *
 * @param state RootState
 */
export const getVideographerElopmentStartPrice = (state: RootState): number | null =>
  getElopmentStartPrice(state, getCurrentVideographerDetails);
export const getVideographerElopmentStartPriceCents = (state: RootState): number | null =>
  getElopmentStartPriceCents(state, getCurrentVideographerDetails);

/**
 * Gets the high end of the photographer elopment price range, in dollars.
 *
 * @param state RootState
 */
export const getVideographerElopmentEndPrice = (state: RootState): number | null =>
  getElopmentEndPrice(state, getCurrentVideographerDetails);
export const getVideographerElopmentEndPriceCents = (state: RootState): number | null =>
  getElopmentEndPriceCents(state, getCurrentVideographerDetails);

// Beauticians
// TODO: Move to new file, this is shared between couples and vendors
export const getBeauticianDetails = (
  state: RootState,
  storefrontId: number
): BeauticianDetails | undefined => {
  const beauticianId = state.entities.beauticians.storefrontToId[storefrontId];
  return state.entities.beauticians.byId[beauticianId];
};

// Vendor only
export const getCurrentBeauticianDetails = (state: RootState): BeauticianDetails | undefined => {
  const storefront = getCurrentStorefront(state);
  if (storefront) {
    return getBeauticianDetails(state, storefront.id);
  }
  return undefined;
};

export const getBeauticianDayOfServiceStartPricePerPersonCents = (
  state: RootState
): number | null => {
  const beauticianDetails = getCurrentBeauticianDetails(state);
  if (beauticianDetails) {
    return beauticianDetails.startPriceCentsPerPerson;
  }
  return null;
};
export const getBeauticianDayOfServiceStartPricePerPerson = (state: RootState): number | null => {
  return centsToDollars(getBeauticianDayOfServiceStartPricePerPersonCents(state));
};

export const getBeauticianMinimumServiceCount = (state: RootState): number | null => {
  const beauticianDetails = getCurrentBeauticianDetails(state);
  if (beauticianDetails) {
    return beauticianDetails.minimumHeadCount;
  }
  return null;
};

// TODO: Move to new file, this is shared between couples and vendors
export const getFloristDetails = (
  state: RootState,
  storefrontId: number
): FloristDetails | undefined => {
  const floristId = state.entities.florists.storefrontToId[storefrontId];
  return state.entities.florists.byId[floristId];
};

// Vendor Only
export const getCurrentFloristDetails = (state: RootState): FloristDetails | undefined => {
  const storefront = getCurrentStorefront(state);
  if (storefront) {
    return getFloristDetails(state, storefront.id);
  }
  return undefined;
};

/**
 * Gets the minimum price the vendor specified.
 *
 * @see vendorPricingMeta
 */
export const getFloristMinimumSpend = (state: RootState): number | null => {
  const floristDetails = getCurrentFloristDetails(state);
  if (floristDetails) {
    return centsToDollars(floristDetails.startPriceCents);
  }
  return null;
};

export const getFloristBridalBouquetStartPriceCents = (state: RootState): number | null => {
  const floristDetails = getCurrentFloristDetails(state);
  if (floristDetails) {
    return floristDetails.bridalBouquetStartPriceCents;
  }
  return null;
};

export const getFloristBridalBouquetStartPrice = (state: RootState): number | null => {
  return centsToDollars(getFloristBridalBouquetStartPriceCents(state));
};

export const getFloristBridalBouquetEndPriceCents = (state: RootState): number | null => {
  const floristDetails = getCurrentFloristDetails(state);
  if (floristDetails) {
    return floristDetails.bridalBouquetEndPriceCents;
  }
  return null;
};
export const getFloristBridalBouquetEndPrice = (state: RootState): number | null => {
  return centsToDollars(getFloristBridalBouquetEndPriceCents(state));
};

export const getFloristLowCenterpieceStartPriceCents = (state: RootState): number | null => {
  const floristDetails = getCurrentFloristDetails(state);
  if (floristDetails) {
    return floristDetails.lowCenterpieceStartPriceCents;
  }
  return null;
};
export const getFloristLowCenterpieceStartPrice = (state: RootState): number | null => {
  return centsToDollars(getFloristLowCenterpieceStartPriceCents(state));
};

export const getFloristLowCenterpieceEndPriceCents = (state: RootState): number | null => {
  const floristDetails = getCurrentFloristDetails(state);
  if (floristDetails) {
    return floristDetails.lowCenterpieceEndPriceCents;
  }
  return null;
};
export const getFloristLowCenterpieceEndPrice = (state: RootState): number | null => {
  return centsToDollars(getFloristLowCenterpieceEndPriceCents(state));
};

export const getWeddingPlannerDetails = (
  state: RootState,
  storefrontId: number
): WeddingPlannerDetails | undefined => {
  const weddingPlannerId = state.entities.planners.storefrontToId[storefrontId];
  return state.entities.planners.byId[weddingPlannerId];
};

/**
 * Gets the current vendor details (could be any type) for the current storefront
 */
export const getCurrentVendorDetails = (state: RootState): AnyVendorDetails | undefined => {
  const storefront = getCurrentStorefront(state);
  switch (storefront?.taxonomyKey) {
    case VENUES_TAXONOMY_KEY:
      return getCurrentVenueDetails(state);
    case PHOTOGRAPHERS_TAXONOMY_KEY:
      return getPhotographerDetails(state, storefront.id);
    case VIDEOGRAPHERS_TAXONOMY_KEY:
      return getVideographerDetails(state, storefront.id);
    case BANDS_DJS_TAXONOMY_KEY:
      return getMusicianDetails(state, storefront.id);
    case CATERING_TAXONOMY_KEY:
    case BAR_SERVICES_TAXONOMY_KEY:
      return getCatererDetails(state, storefront.id);
    case CAKES_DESSERTS_TAXONOMY_KEY:
      return getBakerDetails(state, storefront.id);
    case HAIR_MAKEUP_TAXONOMY_KEY:
      return getBeauticianDetails(state, storefront.id);
    case FLORISTS_TAXONOMY_KEY:
      return getFloristDetails(state, storefront.id);
    case PLANNERS_TAXONOMY_KEY:
      return getWeddingPlannerDetails(state, storefront.id);
    case OFFICIANTS_TAXONOMY_KEY:
      return getOfficiantDetails(state, storefront.id);
    case EXTRAS_TAXONOMY_KEY:
      return getExtrasDetails(state, storefront.id);
    default:
      return undefined;
  }
};

/**
 * Gets the current vendor details for the current storefront except venues (Bio Form)
 */
export const getCurrentVendorDetailsExceptVenues = (
  state: RootState
): AllVendorDetailsExceptVenues | undefined => {
  const storefront = getCurrentStorefront(state);
  switch (storefront?.taxonomyKey) {
    case PHOTOGRAPHERS_TAXONOMY_KEY:
      return getPhotographerDetails(state, storefront.id);
    case VIDEOGRAPHERS_TAXONOMY_KEY:
      return getVideographerDetails(state, storefront.id);
    case BANDS_DJS_TAXONOMY_KEY:
      return getMusicianDetails(state, storefront.id);
    case CATERING_TAXONOMY_KEY:
    case BAR_SERVICES_TAXONOMY_KEY:
      return getCatererDetails(state, storefront.id);
    case CAKES_DESSERTS_TAXONOMY_KEY:
      return getBakerDetails(state, storefront.id);
    case HAIR_MAKEUP_TAXONOMY_KEY:
      return getBeauticianDetails(state, storefront.id);
    case FLORISTS_TAXONOMY_KEY:
      return getFloristDetails(state, storefront.id);
    case PLANNERS_TAXONOMY_KEY:
      return getPlannerDetails(state, storefront.id);
    case OFFICIANTS_TAXONOMY_KEY:
      return getOfficiantDetails(state, storefront.id);
    case EXTRAS_TAXONOMY_KEY:
      return getExtrasDetails(state, storefront.id);
    default:
      return undefined;
  }
};

export const getIsBarServicesOnly = (state: RootState): boolean => {
  const storefront = getCurrentStorefront(state);
  return storefront?.taxonomyKey === BAR_SERVICES_TAXONOMY_KEY;
};

export const getHasStories = createSelector(
  getCurrentCatererDetails,
  (state: RootState) => state.entities.catererStories.byCaterer,
  (caterer, byCaterer) => {
    if (!caterer) {
      return false;
    }
    return (byCaterer[caterer.uuid] || []).length > 0;
  }
);

export const getIsCoverGalleryInProgress = (state: RootState) =>
  (state.vendorStorefront.storefrontDetails?.coverGallery || []).length >= 1;

export const getIsCoverGalleryComplete = (state: RootState) =>
  (state.vendorStorefront.storefrontDetails?.coverGallery || []).length >= 2;

export const getIsPortfolioInProgress = (state: RootState) =>
  (state.vendorStorefront.storefrontDetails?.photoGallery || []).length >= 1;

export const getIsPortfolioComplete = (state: RootState) =>
  (state.vendorStorefront.storefrontDetails?.photoGallery || []).length >= 10;

export const getAreVenueDetailsComplete = (state: RootState) =>
  !_isEmpty(state.vendorStorefront.storefrontDetails?.description);

export const getIsVideoComplete = (state: RootState) => {
  const video = state.vendorStorefront.storefrontDetails?.video;
  return !_isEmpty(video?.videoId);
};

export const getAreSubmittedReviewsComplete = (state: RootState) =>
  (state.vendorStorefront.storefrontDetails?.submittedReviews || []).length >= 2;

/**
 * For the current storefront, get the unique, normalized, emails used in review requests
 *
 * @param {object} state - redux state
 *
 * @return {Array<String>} - unique and normalized email addresses already used to request recommendations from vendors or couples
 * */
export const getUniqueReviewEmails = (state: RootState) => {
  const reviews = state.vendorStorefront.storefrontDetails?.reviews || [];
  const emails = reviews.map((review) => review.email);
  return _uniq(emails.map<string>(normalizeReviewEmailAddress));
};

export const getHasSpaces = (state: RootState) => {
  const storefront = state.vendorStorefront.storefrontDetails;
  if (storefront?.taxonomyKey === VENUES_TAXONOMY_KEY) {
    return storefront.venueDetails.spaces.length > 0;
  }
  return false;
};

export const getHasMenus = (state: RootState) => {
  const storefront = state.vendorStorefront.storefrontDetails;
  if (storefront?.taxonomyKey === VENUES_TAXONOMY_KEY) {
    return storefront.venueDetails.menus.length > 0;
  }
  return false;
};

const getSocialLink = (link: keyof Social) => (state: RootState) => {
  // eslint-disable-next-line react/destructuring-assignment
  const storefront = state.vendorStorefront.storefrontDetails;
  if (storefront && storefront.social) {
    return storefront.social[link];
  }
  return null;
};

export const getWebLink = getSocialLink('web');
export const getFacebookLink = getSocialLink('facebook');
export const getTwitterLink = getSocialLink('twitter');
export const getPinterestLink = getSocialLink('pinterest');
export const getInstagramLink = getSocialLink('instagram');
export const getMatterportLink = getSocialLink('matterport');
export const getSketchfabLink = getSocialLink('sketchfab');

type SuggestedFaq = {
  id: number;
  question: string;
  answer: string;
  required?: boolean;
};

export const getSuggestedFAQs = createSelector(
  getVendorTaxonomyKey,
  getIsBarServicesOnly,
  (taxonomyKey, barServicesOnly): SuggestedFaq[] => {
    switch (taxonomyKey) {
      case VENUES_TAXONOMY_KEY:
        return [
          {
            id: 1,
            question: 'What’s included in your starting rental fee?',
            answer: 'Set up, clean up, tables, chairs, china, flatware, glassware, and linens',
          },
          {
            id: 2,
            question: 'How many hours are included in our booking?',
            answer:
              'Your reservation includes six hours from start to finish, including the time it takes our staff to set up and clean up. Any additional time will be billed at $200 per hour.',
          },
          {
            id: 3,
            question: 'What is your peak season?',
            answer: 'May, June, July, August, September, October, and November',
            required: true,
          },
          {
            id: 4,
            question: 'What is your off-peak season?',
            answer: 'January, February, March, April, and December',
            required: true,
          },
          {
            id: 5,
            question: 'Can we bring our own alcohol?',
            answer: 'Yes, we charge a corkage fee of $10 per bottle for wine and liquor.',
            required: true,
          },
          {
            id: 6,
            question: 'What’s your cancellation policy?',
            answer:
              'Booking fees for our facilities, and all payments made including deposits, are non-refundable. If you cancel your event within 40 days of your booked date, we’ll bill you the full amount of all estimated charges.',
          },
          {
            id: 7,
            question: 'How do we get to your venue from the nearest airport?',
            answer:
              'Our building is located 12 miles away from LaGuardia Airport in Queens, New York. You can reach our venue by public transportation via the subway or train, or by private car or taxi.',
          },
          {
            id: 8,
            question: 'Do you have on-site parking?',
            answer:
              'Yes, valet parking is available at the rate of $35 for up to 24 hours. We do not offer in-and-out privileges or self-parking.',
            required: true,
          },
          {
            id: 9,
            question: 'Do you have accessible facilities?',
            answer:
              'Yes, all our facilities are wheelchair accessible. We also have ramps at the main and side entrances, hearing-impaired fire alarm lights, and trained staff.',
            required: true,
          },
          {
            id: 10,
            question: 'Do we need to get insurance?',
            answer: 'No, our venue is fully insured.',
          },
        ];

      case PHOTOGRAPHERS_TAXONOMY_KEY:
        return [
          {
            id: 1,
            question: 'How far in advance should we book you for our wedding?',
            answer:
              'Typically, couples book me about 13 months before their wedding date. I encourage you to reach out as soon as possible about my availability, even if you’re planning on a shorter time frame.',
          },
          {
            id: 2,
            question: 'Do you work with assistants or other photographers?',
            answer:
              'Sometimes, I’ll bring one or more assistant photographers depending on the size of your wedding. Don’t worry, this won’t cost you extra.',
          },
          {
            id: 3,
            question: 'Will you edit all our pictures?',
            answer:
              'Definitely. All final images will be edited for color, saturation, exposure, and sharpness to ensure they look beautiful. I also offer retouching services like blemish removal and teeth whitening for an additional charge.',
          },
          {
            id: 4,
            question: 'Do you also offer video services?',
            answer:
              'No, I prefer focusing on photography only, so you can get the best wedding pictures possible.',
          },
          {
            id: 5,
            question: 'How long will it take to see our pictures?',
            answer:
              'My turnaround time varies by season. Typically, you’ll be able to view your photos online about 4-6 weeks after your wedding. Within this time, you can also expect to receive a USB drive with high-resolution image files, so you can share the photos with your loved ones and order prints.',
          },
          {
            id: 6,
            question: 'How many pictures will we get?',
            answer:
              'You can expect about 80 to 100 images per hour of photographing. But keep in mind this estimate varies depending on your wedding package, number of guests, venue type, and other factors.',
          },
          {
            id: 7,
            question: 'Do you have insurance?',
            answer:
              'I do! Just let me know if your venue requires a certificate of liability, and I’ll send that over to them.',
          },
          {
            id: 8,
            question: 'What’s your back-up plan if you can’t photograph our wedding as planned?',
            answer:
              'In my 12 years of experience as a wedding photographer, I’ve never cancelled a booking. In the very rare event that I’m unable to photograph your wedding, I’ll make sure you’re covered by one of my colleagues or my contracted assistant. I’ll also provide you with a list of photographers from the many networking groups I’m in, so you can find the perfect replacement.',
          },
        ];
      case FLORISTS_TAXONOMY_KEY:
        return [
          {
            id: 1,
            question: 'What is your service area? Do you have a delivery fee for flowers?',
            answer:
              'We offer delivery, setup, and breakdown services within a 100 mile radius of Los Angeles. We have a $2,500 minimum for floral delivery (and $1,000 minimum for pickup). Since many weddings require a professional set up, we also charge a separate fee for event installation.',
          },
          {
            id: 2,
            question: 'Do you have a minimum spend for weddings?',
            answer:
              'We have a $1,500 minimum for custom floral pieces. We also offer a complete wedding package starting at $495 with pre-selected flower types and quantities.',
          },
          {
            id: 3,
            question: 'What should we expect at our consultation? Do we need to bring anything?',
            answer:
              'Consultations last approximately 1 hour but can take up to 2 for larger weddings. Please come prepared with details about your wedding like your budget and style, as well as any inspiration you have for your wedding flowers. This can include images of your venue, photos of your wedding attire, fabric swatches, your color palette, photos from social media, and more. The more information and ideas you provide, the better we can assist you. Also, if you plan on using your own containers, please bring them so we can evaluate and give you an accurate quote based on their size. After your consultation, we will draw up a proposal with prices and email it to you.',
          },
          {
            id: 4,
            question: 'Do you handle flower breakdown after the ceremony?',
            answer:
              'Yes, we offer breakdown services along with delivery, set up, and flower transfer from the ceremony to the reception.',
          },
          {
            id: 5,
            question: 'How far in advance should we order our wedding flowers?',
            answer:
              'It’s never too early to get in touch about your wedding, but we recommend having a few details figured out before you do. For example: Have you selected the location for your ceremony and/or reception? What’s your wedding style? Do you know how many guests you’ll be expecting? Typically, our couples reach out around 6 to 8 months before their big day.',
          },
          {
            id: 6,
            question: 'Can I see a mockup of proposed flowers?',
            answer:
              'Yes! After the contract is signed, we provide one mockup, typically a table centerpiece. We prefer to create this between 30 and 60 days before the wedding to take into account seasonal flowers and time to make adjustments before the final proposal and vendor commitments are due.',
          },
          {
            id: 7,
            question: 'What flowers are in season for our wedding?',
            answer:
              'During your consultation, we will go over all the gorgeous in-season floral offerings available for your wedding date. We love supporting local growers and regional flowers and greenery!',
          },
          {
            id: 8,
            question: 'Can you provide any decor other than flowers?',
            answer:
              'Yes! We offer a variety of rentals for an additional cost from aisle runners to candelabras, arches, candles, mirrors, and more. Get in touch to see our full list.',
          },
          {
            id: 9,
            question: 'Can you preserve our flowers after the wedding?',
            answer:
              'Sorry, we do not offer flower preservation. However, we’re happy to recommend a few local vendors who provide this service. Just let us know.',
          },
        ];
      case VIDEOGRAPHERS_TAXONOMY_KEY:
        return [
          {
            id: 1,
            question: 'Do you film destination weddings?',
            answer:
              'Definitely! While we’re based in Brooklyn, New York, we’re available for destination weddings worldwide. If your wedding is over 50 miles outside the New York City metropolitan area, we’re happy to come to your city for an additional fee to cover the cost of travel and reasonable accommodations.',
          },
          {
            id: 2,
            question: 'Do you film elopements?',
            answer:
              'Absolutely. Reach out anytime to ask about our special pricing for elopements! Please note your elopement must have fewer than 10 total guests.',
          },
          {
            id: 3,
            question: 'How far in advance should we book you for our wedding?',
            answer:
              'Once you know your wedding date, we recommend reaching out as soon as possible about our availability. We film a limited number of weddings every year to ensure our couples receive the highest quality service, so our calendar typically fills up months in advance.',
          },
          {
            id: 4,
            question: 'Do you have insurance?',
            answer:
              'Yes! We’re happy to send a copy of our certificate of liability insurance to your venue or any other vendors upon request.',
          },
          {
            id: 5,
            question: 'Can you still film our outdoor wedding if it rains on our wedding day?',
            answer:
              'Unfortunately, we cannot film outdoors in the rain or other inclement weather conditions like snow and hail.',
          },
          {
            id: 6,
            question: 'Who will edit our wedding video?',
            answer: 'All footage we film is edited in-house by our incredible editing team.',
          },
          {
            id: 7,
            question: 'Can you do a same-day edit?',
            answer:
              'We’re happy to offer a same-day edit for an additional fee. After your ceremony, one of your videographers will work with an editor on set to put together your same-day edit. We’ll make sure the final video is done in time for your reception.',
          },
          {
            id: 8,
            question: 'Can anyone else see our wedding video?',
            answer:
              'No, we’ll send a private link to share your final wedding video with you. Only people with that link will be able to see your video.',
          },
          {
            id: 9,
            question: 'How many videographers should we book for our wedding?',
            answer:
              'All of our wedding packages include at least two videographers. If you’re having a larger wedding with 100 or more guests, we recommend booking an additional videographer to ensure we’re able to capture every moment of your special day.',
          },
        ];
      case CATERING_TAXONOMY_KEY: {
        const commonCatererFAQ = [
          {
            id: 1,
            question: 'Is there a delivery fee?',
            answer:
              'Yes, we charge a standard fee for delivery within Manhattan. Additional fees may apply to deliveries to other boroughs.',
          },
          {
            id: 2,
            question: 'Do you have a minimum for weddings?',
            answer: 'Yes, our event minimum is $XX for all weddings.',
          },
          {
            id: 3,
            question: 'Do you offer tastings?',
            answer:
              'Yes, we offer tastings for you and a guest for a small fee. If you sign a contract with us beforehand, we can waive this fee.',
          },
          {
            id: 4,
            question: 'How many times do we meet before the wedding?',
            answer:
              'We recommend meeting 2 more times after your tasting. One meeting will focus on finalizing menus and headcount. One to go over final venue and wedding logistics.',
          },
          {
            id: 5,
            question: 'Do you offer help with rentals?',
            answer:
              'We are full service. We will take you on a walk-through of available rentals and work with you to find options within your budget.',
          },
          {
            id: 6,
            question: 'How far out do you recommend booking your services?',
            answer: 'For summer and fall weddings, we recommend at least 10 months in advance.',
          },
          {
            id: 7,
            question: 'What is your service area?',
            answer:
              'We serve all five boroughs in Manhattan but charge a delivery fee for Queens, Brooklyn and the Bronx.',
          },
        ];
        const foodAndBevFAQ = [
          {
            id: 8,
            question: 'Can you accommodate dietary restrictions?',
            answer:
              'We can provide individual Kosher or Halal meals and customize fully vegetarian or vegan menus.',
          },
          {
            id: 9,
            question: 'Do you offer cake cutting services?',
            answer:
              'For a small per person fee, we can cut and serve your wedding cake to your guests.',
          },
        ];

        return barServicesOnly ? commonCatererFAQ : [...commonCatererFAQ, ...foodAndBevFAQ];
      }
      case BAR_SERVICES_TAXONOMY_KEY:
        return [
          {
            id: 1,
            question: 'Is there a delivery fee?',
            answer:
              'Yes, we charge a standard fee for delivery within Manhattan. Additional fees may apply to deliveries to other boroughs.',
          },
          {
            id: 2,
            question: 'Do you have a minimum for weddings?',
            answer: 'Yes, our event minimum is $XX for all weddings.',
          },
          {
            id: 3,
            question: 'Do you offer tastings?',
            answer:
              'Yes, we offer tastings for you and a guest for a small fee. If you sign a contract with us beforehand, we can waive this fee.',
          },
          {
            id: 4,
            question: 'How many times do we meet before the wedding?',
            answer:
              'We recommend meeting 2 more times after your tasting. One meeting will focus on finalizing menus and headcount. One to go over final venue and wedding logistics.',
          },
          {
            id: 5,
            question: 'Do you offer help with rentals?',
            answer:
              'We are full service. We will take you on a walk-through of available rentals and work with you to find options within your budget.',
          },
          {
            id: 6,
            question: 'How far out do you recommend booking your services?',
            answer: 'For summer and fall weddings, we recommend at least 10 months in advance.',
          },
          {
            id: 7,
            question: 'What is your service area?',
            answer:
              'We serve all five boroughs in Manhattan but charge a delivery fee for Queens, Brooklyn and the Bronx.',
          },
        ];
      case BANDS_DJS_TAXONOMY_KEY:
        return [
          {
            id: 1,
            question: 'What do you wear?',
            answer: '',
          },
          {
            id: 2,
            question: 'Do you learn new songs?',
            answer: '',
          },
          {
            id: 3,
            question: 'What makes you different from other musicians?',
            answer: '',
          },
          {
            id: 4,
            question: 'Do you take music requests?',
            answer: '',
          },
          {
            id: 5,
            question: 'Do you provide music for wedding ceremonies or cocktail receptions?',
            answer: '',
          },
          {
            id: 6,
            question: 'Can you provide specialty, religious, or ethnic musicians?',
            answer: '',
          },
          {
            id: 7,
            question: 'Are you fully insured?',
            answer: '',
          },
          {
            id: 8,
            question: 'What is your style?',
            answer: '',
          },
          {
            id: 9,
            question: 'How early do you come to set up?',
            answer: '',
          },
          {
            id: 10,
            question: 'Do you provide other services, such as lighting or photo booths?',
            answer: '',
          },
          {
            id: 11,
            question: 'What is your travel and break down fee?',
            answer: '',
          },
          {
            id: 12,
            question: 'What are your sales taxes?',
            answer: '',
          },
        ];
      case CAKES_DESSERTS_TAXONOMY_KEY:
        return [
          {
            id: 1,
            question: 'What dietary accommodations are available?',
            answer: '',
          },
          {
            id: 2,
            question: 'Does your price include tastings?',
            answer: '',
          },
          {
            id: 3,
            question: 'Are you licensed by the state health department?',
            answer: '',
          },
          {
            id: 4,
            question: 'What special cake items are included?',
            answer: '',
          },
          {
            id: 5,
            question: 'Does your price include delivery?',
            answer: '',
          },
          {
            id: 6,
            question: 'How do I know what size to order?',
            answer: '',
          },
          {
            id: 7,
            question: 'Do you offer custom designs?',
            answer: '',
          },
          {
            id: 8,
            question: 'Do you offer cake alternatives?',
            answer: '',
          },
        ];
      case HAIR_MAKEUP_TAXONOMY_KEY:
        return [];
      case PLANNERS_TAXONOMY_KEY:
        return [
          {
            id: 1,
            question:
              'Describe your experience. How many weddings have you planned, and what are they like? Any specialties?',
            answer: '',
          },
          {
            id: 2,
            question: 'Are you part of a team? Who will we be working with?',
            answer: '',
          },
          {
            id: 3,
            question: 'Do you also plan other events, like showers or rehearsal dinners?',
            answer: '',
          },
          {
            id: 4,
            question: 'How much time should we expect to spend with you?',
            answer: '',
          },
          {
            id: 5,
            question: 'Do you take on multiple weddings in a day or weekend?',
            answer: '',
          },
        ];
      case OFFICIANTS_TAXONOMY_KEY:
        return [
          {
            id: 1,
            question: 'How long have you been in business?',
            answer: '',
          },
          {
            id: 2,
            question: 'How many weddings have you done?',
            answer: '',
          },
          {
            id: 3,
            question: 'Can we personalize our ceremony?',
            answer: '',
          },
          {
            id: 4,
            question: 'Do you do rehearsals? Is that included in your cost?',
            answer: '',
          },
          {
            id: 5,
            question: 'We’re legally married already. Can you still do our ceremony?',
            answer: '',
          },
          {
            id: 6,
            question: 'Do you provide or require premarital counseling?',
            answer: '',
          },
        ];
      case EXTRAS_TAXONOMY_KEY:
        return [];
      default:
        Logger.error(`Unsupported vendorType in getSuggestedFAQs: ${taxonomyKey}`);
        return [];
    }
  }
);

export const getRequiredFaqs = createSelector(getSuggestedFAQs, (suggestedFAQs) => {
  return suggestedFAQs.filter((faq) => faq.required);
});

export const getOptionalFaqs = createSelector(getSuggestedFAQs, (suggestedFAQs) => {
  return suggestedFAQs.filter((faq) => !faq.required);
});

/**
 * Returns a lookup by question
 */
export const getFaqAnswers = createSelector(
  (state: RootState) => state.vendorStorefront.storefrontDetails?.faqs,
  (faqs): Record<string, boolean> => {
    return (
      faqs?.reduce((result, faq) => {
        return { ...result, [faq.question]: true };
      }, {}) || {}
    );
  }
);

export const getIsFaqSectionComplete = createSelector(
  (state: RootState) => state.vendorStorefront.storefrontDetails?.faqs || [],
  getRequiredFaqs,
  getFaqAnswers,

  (faqs, requiredFaq, answersByQuestion) => {
    if (requiredFaq && requiredFaq.length > 0) {
      return requiredFaq.every((faq) => answersByQuestion[faq.question]);
    }
    return Boolean(faqs && faqs.length > 0);
  }
);

export const getIsFaqSectionInProgress = createSelector(
  (state: RootState) => state.vendorStorefront.storefrontDetails?.faqs || [],
  getRequiredFaqs,
  getFaqAnswers,

  (faqs, requiredFaq, answersByQuestion) => {
    if (requiredFaq && requiredFaq.length > 0) {
      return requiredFaq.some((faq) => answersByQuestion[faq.question]);
    }
    return Boolean(faqs && faqs.length > 0);
  }
);

/** Gets metadata about services offered for a vendor type using the vendor type in the state */
const getServicesOfferedMetaData = createSelector(getVendorTaxonomyKey, (taxonomyKey) => {
  return getServicesOfferedMetaForVendorTaxonomyKey(taxonomyKey);
});

export const getSelectedServices = createSelector(
  getServicesOfferedMetaData,
  (state: RootState) => state.vendorStorefront.storefrontDetails?.options || [],
  (metaData, options) => {
    const parentKeys = metaData.map((data) => data.parentKey);
    return getOptionsForParentKeys(options, parentKeys);
  }
);

export const getAreServicesComplete = createSelector(getSelectedServices, (selectedServices) => {
  return selectedServices.length > 0;
});

export const getHasExtraCosts = (state: RootState) => {
  const options = state.vendorStorefront.storefrontDetails?.options || [];
  return getOptionsForParentKeys(options, ['caterer-extra-costs']).length > 0;
};

/**
 * Selectors for match-setting data from service
 */

export const getTargetCoupleBudgetRange = (state: RootState) => {
  const budgetMin = state.vendorStorefront.storefrontDetails?.targetCoupleBudgetMin || null;
  const budgetMax = state.vendorStorefront.storefrontDetails?.targetCoupleBudgetMax || null;

  return [budgetMin, budgetMax];
};

export const getTargetPerPersonBudgetRange = (state: RootState) => {
  const budgetPerPersonMin =
    state.vendorStorefront.storefrontDetails?.targetPerPersonBudgetMin || null;
  const budgetPerPersonMax =
    state.vendorStorefront.storefrontDetails?.targetPerPersonBudgetMax || null;

  return [budgetPerPersonMin, budgetPerPersonMax];
};

const getLeadPreferenceOptionKeys = createSelector(getVendorTaxonomyKey, (taxonomyKey) => {
  const facetsUsedInLeadPreferences = LEAD_PREFERENCE_FACETS[taxonomyKey] || {};
  return Object.keys(facetsUsedInLeadPreferences).map(
    (facetSymbol) => facetsUsedInLeadPreferences[facetSymbol].key
  );
});

const getLeadPreferencesOptions = (state: RootState) => {
  const options = state.vendorStorefront.storefrontDetails?.options || [];
  const keysToMatch = getLeadPreferenceOptionKeys(state);
  const matchedKeys = getOptionsForParentKeys(options, keysToMatch).map(
    (option) => option.parentKey
  );
  return matchedKeys;
};

export const getAreLeadPreferencesComplete = createSelector(
  getTargetCoupleBudgetRange,
  getTargetPerPersonBudgetRange,
  getLeadPreferenceOptionKeys,
  getLeadPreferencesOptions,
  (totalBudgetValues, perPersonBudgetValues, keys, matchOptions) => {
    const [min, max] = totalBudgetValues;
    const [budgetPerPersonMin, budgetPerPersonMax] = perPersonBudgetValues;
    if (min === null || max === null) {
      return false;
    }
    if (budgetPerPersonMin === null || budgetPerPersonMax === null) {
      return false;
    }
    return Array.from(new Set(matchOptions)).length === keys.length;
  }
);

/**
 * Determines, from the redux state, if the storefront has completed their video gallery.
 *
 * completion in this case means at least 2 videos in the gallery
 *
 * @param {Object} state - The current redux state
 * @returns true if the storefront has a complete video gallery, false otherwise.
 */
export const getHasVideoGallery = createSelector(
  getVideoGallery,
  (videoGallery) => videoGallery && videoGallery.length > 1
);

export const getAvailableThroughAsNumber = (state: RootState) => {
  return state.vendorStorefront.storefrontDetails?.availableThrough || null;
};

export const getAvailableThrough = (state: RootState) => {
  const availableThrough = getAvailableThroughAsNumber(state);
  return availableThrough ? new Date(availableThrough) : null;
};

export const getPubliclyViewable = (state: RootState) => {
  return Boolean(state.vendorStorefront.storefrontDetails?.publiclyViewable);
};
